<template>
	<div class="modal fade action-sheet opax" id="contractOrnamentModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ ornament.id?'Editar adorno':'Nuevo adorno'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md-4">
				        	<label>Nombres del fallecido *</label>
				        	<input type="text" v-model="ornament.deceased_name" class="form-control" placeholder="Nombre del fallecido">
				        </div>
                        <div class="col-12 col-md-4">
				        	<label>Primer apellido del fallecido *</label>
				        	<input type="text" v-model="ornament.deceased_surname" class="form-control" placeholder="Primer apellido del fallecido">
				        </div>
                        <div class="col-12 col-md-4">
				        	<label>Segundo apellido del fallecido *</label>
				        	<input type="text" v-model="ornament.deceased_lastname" class="form-control" placeholder="Segundo apellido del fallecido">
				        </div>
                        <div class="col-12 col-md-6">
                            <label>Proveedor *</label>
				            <select class="form-control" v-model="ornament.id_provider">
				            	<option v-for="p in providers" v-bind:value="p.id">{{ p.name }}</option>
				            </select>
                        </div>
                        <div class="col-12 col-md-6">
                            <label>Modelo de corona *</label>
                            <input type="text" v-model="ornament.model" class="form-control" placeholder="Modelo de corona">
                        </div>
                        <div class="col-12 col-md-4">
				        	<label>Lugar de entrega *</label>
				        	<input type="text" v-model="ornament.delivery_place" class="form-control" placeholder="Lugar de entrega">
				        </div>
                        <div class="col-12 col-md-4">
				        	<label>Fecha *</label>
				        	<datepicker v-model="ornament.date" style="display: block;width: 100%"  v-bind:lang="lang" value-type="YYYY-MM-DD" format="DD/MM/YYYY" type="date" placeholder="Fecha de servir" input-class="form-control"></datepicker>
				        </div>
                        <div class="col-12 col-md-4">
				        	<label>Hora de servir *</label>
                            <datepicker v-model="ornament.time" style="display: block;width: 100%"  v-bind:lang="lang" value-type="HH:mm:ss" format="HH:mm" type="time" placeholder="Hora de servir" input-class="form-control"></datepicker>
				        </div>
				        <div class="col-12 col-md-12">
				        	<label>Observaciones</label>
				        	<input type="text" v-model="ornament.observations" class="form-control" placeholder="Observaciones">
				        </div>
				        <div class="col-12">
				        	<label>Texto adorno *</label>
				        	<textarea v-model="ornament.text" class="form-control" placeholder="Texto adorno"></textarea>
				        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
    export default {
        components: { Datepicker },
        mounted() {
        	this.$root.$on('contract-floral-ornaments.context',this.loadOrnaments);
            this.$root.$on('contract-floral-ornaments.add',this.openModal);
            this.$root.$on('contract-floral-ornaments.edit',this.openModal);
        },
        data(){
            return {
            	base: window.urls['contract.floral-ornaments.index'],
                saving: false,
                lang: {
					formatLocale: {
	          			firstDayOfWeek: 1,
	              	},
	              	monthBeforeYear: false,
	          	},
                ornaments: [],
                providers: [],
                ornament:{
                    id_contract: null,
                    id_provider: null,
                    id_ornament: null,
                    model: null,
                    deceased_name: null,
                    deceased_surname: null,
                    deceased_lastname: null,
                    delivery_place: null,
					color: null,
					observations: null,
					text: null,
                    date: null,
                    time: null
                },
                errors: {
                    id_provider: false,
                    id_ornament: null,
                    deceased_name: false,
                    deceased_surname: false,
                    deceased_lastname: false,
                    delivery_place: false,
                    text: false,
                    color: false,
                    date: false,
                    time: false
                },
            }
        },
        methods:{
        	openModal(data){
        		var contract = data.contract;
        		var ornament = data.ornament;
        		var deceased = data.deceased;
                if(ornament){
                    this.ornament = {
                        id_contract: ornament.id_contract,
                        id_provider: ornament.id_provider,
                        id_ornament: ornament.id_ornament,
                        model: ornament.model,
                        deceased_name: ornament.deceased_name,
                        deceased_lastname: ornament.deceased_lastname,
                        deceased_lastname: ornament.deceased_lastname,
                        delivery_place: ornament.delivery_place,
                        color: ornament.color,
                        observations: ornament.observations,
                        text: ornament.text,
                        date: ornament.date,
                        time: ornament.time
                    };
                }else{
                    this.ornament = {
						id_contract: contract.id,
                        id_provider: null,
                        id_ornament: null,
                        model: null,
                        deceased_name: deceased?.name,
                        deceased_lastname: deceased?.lastname,
                        deceased_surname: deceased?.surname,
                        delivery_place: null,
                        color: null,
                        observations: null,
                        text: null,
                        date: null,
                        time: null,
                    };
                }
                this.resetErrors();
        		$('#contractOrnamentModal').modal({'backdrop':'static'});
        	},
        	loadOrnaments(data){
				this.ornaments = data.ornaments;
                this.providers = data.providers;
			},
            resetErrors(){
                this.errors={
                    id_provider: false,
                    id_ornament: null,
                    model: false,
                    deceased_name: false,
                    delivery_place: false,
                    color: false,
                    text: false,
                    date: false,
                    time: false
                };
            },
            validate(){
            	var valid = true;
                this.errors.id_provider = this.ornament.id_provider == null || this.ornament.id_provider == undefined || this.ornament.id_provider == "";
                this.errors.model = this.ornament.model == null || this.ornament.model == undefined || this.ornament.model == "";
                this.errors.deceased_name = this.ornament.deceased_name == null || this.ornament.deceased_name == undefined || this.ornament.deceased_name == "";
                this.errors.delivery_place = this.ornament.delivery_place == null || this.ornament.delivery_place == undefined || this.ornament.delivery_place == "";
                this.errors.text = this.ornament.text == null || this.ornament.text == undefined || this.ornament.text == "";
                this.errors.date = this.ornament.date == null || this.ornament.date == undefined || this.ornament.date == "";
                this.errors.time = this.ornament.time == null || this.ornament.time == undefined || this.ornament.time == "";

                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url = this.base;
                var vm = this;
                if(this.ornament.id) url += '/' + this.ornament.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.ornament,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contract-floral-ornaments.updated', response.data);
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el adorno',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#contractOrnamentModal').modal('hide');
            }
        }
    }
</script>
