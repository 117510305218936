<template>
	<div class="mx-auto text-center">
		<a v-bind:href="url" target="_blank">
  			<div class="document-thumbnail" v-if="isImage" v-bind:style="'background-image: url(\'' + url +'\');'"></div>
  			<img width="130" v-if="isPdf" src="images/pdficon.png" />
  		</a>
  	</div>
</template>

<script>
	export default {
	  	props: ['file'],
	  	data(){
	  		return {
	  			url: null,
	  			isImage: false,
	  			isPdf: false
	  		}
	  	},
	  	mounted(){
	  		this.detectPdf();
	  		this.detectImage();
	  		if(this.file.path != null)
	  			this.url = this.file.path;
  			else
	  			this.url = URL.createObjectURL(this.file);
	  	},
	  	methods: {
	  		detectPdf(){
	  			if(!this.file.id)
	  				this.isPdf = this.file.type == "application/pdf";
	  			else
	  				this.isPdf = this.file.mime == "application/pdf";
	  		},
	  		detectImage(){
	  			if(!this.file.id)
	  				var image = this.file.type.indexOf("image/");
	  			else
	  				var image = this.file.mime.indexOf("image/");
	  			this.isImage = image != -1;
	  		},
	  	}
	}
</script>