<template>
	<div>
		<div class="row">
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_zinc" /> Zinc
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_cross" /> Cruz
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_crist" /> Cristo
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_room_reasons" /> Motivos sala
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_funeral_mass" /> Misa tanatorio
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_funeral_response" /> Responso tanatorio
				</label>
			</div>
		</div>
		<div class="row">
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_crematory_response" /> Responso cem/cre.
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_music" /> Música
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_remember" /> Recordatorios
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_obituary_press" /> Esquela prensa
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_obituary_portal" /> Esquela portal
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_dress" /> Vestir
				</label>
			</div>
		</div>
		<div class="row">
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_shave" /> Afeitado
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_makeup" /> Maquillar
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_conditioning" /> Acondicionamiento
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_signs_book" /> Libro de firmas
				</label>
			</div>
			<div class="col-6 col-md-2">
				<label>
					<input type="checkbox" v-model="contract.mounting_gestory" /> Gestoría
				</label>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4">
				<label>Ropa en</label>
				<input type="text" class="form-control" v-model="contract.clothes_in" placeholder="Ropa en"/>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4">
				<label>Catering</label>
				<input type="text" class="form-control" v-model="contract.mounting_catering" placeholder="Catering"/>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md">
				<label>Fecha misa</label>
				<input-date v-model="contract.mounting_mass_date" placeholder="Fecha misa" input-class="form-control"></input-date>
			</div>
			<div class="col-12 col-md">
				<label>Hora misa</label>
				<input-time v-model="contract.mounting_mass_hour" placeholder="Hora misa" input-class="form-control"></input-time>
			</div>
			<div class="col-12 col-md">
				<label>Lugar misa</label>
				<input type="text" class="form-control" v-model="contract.mounting_mass_place" placeholder="Lugar misa"/>
			</div>
			<div class="col-12 col-md">
                <label type="hidden">Parroquía</label>
                <input spellcheck="true" type="text" class="form-control" v-model="contract.mounting_parish_place" placeholder="Parroquía"/>
            </div>
			<div class="col-12 col-md">
                <label type="hidden">Honorarios del Párroco</label>
                <input spellcheck="true" type="number" class="form-control" v-model="contract.mounting_payment_priest" placeholder="HONORARIOS DEL PÁRROCO"/>
            </div>
		</div>
		<hr />
		<legend>Feretros y otro material</legend>
		<div class="row">
			<div class="col-12 col-md">
				<label>Féretro</label>
				<select class="form-control" v-model="contract.coffin">
					<option value="open">Abierto</option>
					<option value="closed">Cerrado</option>
				</select>
			</div>
			<div class="col-12 col-md">
				<label>Arca <a v-if="isAdmin" href="javascript:void(0)" v-on:click="addArk()">añadir</a></label>
				<autocomplete-component v-model="contract.coffin_arc" v-bind:options="arks" v-bind:key-code="'id'" v-bind:key-label="'name'" v-on:change="loadCode()"></autocomplete-component>
			</div>
			<div class="col-12 col-md">
				<label>Código arca</label>
				<input type="text" class="form-control" v-model="contract.coffin_arc_code" placeholder="Código arca" readonly />
			</div>
			<div class="col-12 col-md">
				<label>Saco nº + Zinc</label>
				<input type="text" class="form-control" v-model="contract.coffin_bag_code" placeholder="Saco nº + Zinc"/>
			</div>
			<div class="col-12 col-md">
				<label>Cantidad salida</label>
				<input type="text" class="form-control" v-model="contract.coffin_outcoming_amount" placeholder="Cantidad salida"/>
			</div>
			<div class="col-12 col-md">
				<label>Código urna</label>
				<input type="text" class="form-control" v-model="contract.coffin_orn_code" placeholder="Código urna"/>
			</div>
			<div class="col-12 col-md">
				<label>Bolsa zinc</label>
				<input type="text" class="form-control" v-model="contract.coffin_zinc_bag" placeholder="Bolsa zinc"/>
			</div>
		</div>
		<hr />
		<legend>Vehículos</legend>
		<div class="row">
			<div class="col-12 col-md-3">
				<label>Montador asignado</label>
				<input type="text" class="form-control" v-model="contract.vehicle_mounter_asigned" placeholder="Montador asignado" />
			</div>
			<div class="col-12 col-md-3">
				<label>Conductor</label>
				<input type="text" class="form-control" v-model="contract.vehicle_driver" placeholder="Conductor" />
			</div>
			<div class="col-12 col-md-2">
				<label>Vehículo</label>
				<select class="form-control" v-model="contract.vehicle_vehicle" v-on:change="loadVehicle()">
					<option v-for="v in vehicles" v-bind:value="v.id">{{ v.model }} {{ v.enrollment }}</option>
				</select>
			</div>
			<div class="col-12 col-md-2">
				<label>Matrícula</label>
				<input type="text" class="form-control" v-model="contract.vehicle_enrollment" placeholder="Matrícula" readonly />
			</div>
			<div class="col-12 col-md-2">
				<label>Kilómetros</label>
				<input type="text" class="form-control" v-model="contract.vehicle_kilometers" placeholder="Kilómetros" />
			</div>
		</div>
		<hr />
		<legend>Duelos</legend>
		<div class="row">
			<div class="col-12 col-md-3">
				<label>Coches de duelo</label>
				<input type="number" min="0" max="10" class="form-control" v-model="contract.vehicle_duels"  placeholder="Coches de duelo"/>
			</div>
			<div class="col-12 col-md-3">
				<label>Empresa C. duelos</label>
				<input type="text" class="form-control" v-model="contract.company_vehicle_duels"  placeholder="Empresa C. duelos"/>
			</div>
			<div class="col-12 col-md-3">
				<label>Solicitado</label>
				<select class="form-control" v-model="contract.requested_vehicle_duels">
					<option value="1">Sí</option>
					<option value="0">No</option>
				</select>
			</div>
			<div class="col-12 col-md-8">
				<label>Recorrido coches duelo</label>
				<input type="text" class="form-control" v-model="contract.vehicle_duels_origin" placeholder="Recorrido coches duelo" />
			</div>
			<div class="col-12 col-md-3">
				<label>Hora inicio recorrido</label>
				<datepicker v-model="timeStart" style="display: block;width: 100%"  v-bind:lang="lang" format="HH:mm" type="time" placeholder="Hora salida sala" v-on:change="changeStartTime"></datepicker>
			</div>
		</div>
		<hr />
		<legend>Conservación - Embalsamamiento</legend>
		<div class="row">
			<div class="col-12 col-md-3">
				<label>Técnica tanatopraxia</label>
				<select class="form-control" v-model="contract.thanatopraxy_technique" placeholder="Técnica tanatopraxia">
					<option value="conservation">Conservación</option>
					<option value="embalmment">Embalsamamiento</option>
				</select>
			</div>
			<div class="col-12 col-md-3">
				<label>Fecha tanatopraxia</label>
				<datepicker v-model="dateTanatopraxy" style="display: block;width: 100%"  v-bind:lang="lang" format="DD/MM/YYYY" type="date" placeholder="Fecha tanatopraxia" v-on:change="changeDate" v-bind:input-class="{'form-control is-invalid':errors.thanatopraxy_date,'form-control':!errors.thanatopraxy_date}"></datepicker>
			</div>
			<div class="col-12 col-md-3">
				<label>Hora tanatopraxia</label>
				<datepicker v-model="timeTanatopraxy" style="display: block;width: 100%"  v-bind:lang="lang" format="HH:mm" type="time" placeholder="Hora tanatopraxia" v-on:change="changeTime" v-bind:input-class="{'form-control is-invalid':errors.thanatopraxy_time,'form-control':!errors.thanatopraxy_time}"></datepicker>
			</div>
			<div class="col-12 col-md-3">
				<label>Lugar tanatopraxia</label>
				<input type="text" class="form-control" v-model="contract.thanatopraxy_place" placeholder="Lugar tanatopraxia"/>
			</div>
			<div class="col-12">
				<label>Técnico responsable</label>
				<input type="text" class="form-control" v-model="contract.thanatopraxy_technical" placeholder="Técnico responsable"/>
			</div>
			<div class="col-12 col-md-6">
				<label>Empresa tanatopraxia</label>
				<input type="text" class="form-control" v-model="contract.thanatopraxy_company" placeholder="Empresa tanatopraxia"/>
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-6">
				<label>Peso</label>
				<input type="text" class="form-control" v-model="contract.thanatopraxy_weight" placeholder="Peso"/>
			</div>
			<div class="col-12 col-md-6">
				<label>Talla</label>
				<input type="text" class="form-control" v-model="contract.thanatopraxy_height" placeholder="Talla"/>
			</div>
			<div class="col-12 col-md-12">
				<label>Procedencia</label>
				<input type="text" class="form-control" v-model="contract.thanatopraxy_origin" placeholder="Procedencia"/>
			</div>
			<div class="col-12">
				<label>Observaciones montaje</label>
				<textarea class="form-control" v-model="contract.thanatopraxy_observations" placeholder="Observaciones montaje"></textarea>
			</div>
		</div>
	</div>
</template>
<script>
	import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
	export default{
		components: { Datepicker },
		props: ['contract','vehicles','arks'],
		mounted(){
			this.initialize();
		},
		data(){
			return {
				isAdmin: user_role == 'admin',
				dateTanatopraxy: null,
				timeTanatopraxy: null,
				timeStart: null,
				lang: {
					formatLocale: {
	          			firstDayOfWeek: 1,
	              	},
	              	monthBeforeYear: false,
	          	},
      			errors:{

          		}
			}
		},
		methods: {
			initialize(){
				this.dateTanatopraxy = this.contract.thanatopraxy_date != null? new Date(this.contract.thanatopraxy_date): null;
				this.timeTanatopraxy = this.contract.thanatopraxy_time != null? new Date("1970-01-01 " + this.contract.thanatopraxy_time): null;
				
			},
			changeDate(evt){
				if(evt!=null) this.contract.thanatopraxy_date = moment(evt).format('YYYY-MM-DD');
            	else this.contract.thanatopraxy_date = null;
			},
			changeTime(evt){
				if(evt!=null) this.contract.thanatopraxy_time = moment(evt).format('HH:mm');
	            else this.contract.thanatopraxy_time = null;
			},
			changeStartTime(evt){
				if(evt!=null) this.contract.start_vehicle_duels = moment(evt).format('HH:mm');
	            else this.contract.start_vehicle_duels = null;
			},
			loadCode(){
				var idArk = this.contract.coffin_arc;
				for(var i in this.arks){
					if(this.arks[i].id == idArk)
						this.contract.coffin_arc_code = this.arks[i].code;
				}
			},
			loadVehicle(){
				var idVehicle = this.contract.vehicle_vehicle;
				for(var i in this.vehicles){
					if(this.vehicles[i].id == idVehicle)
						this.contract.vehicle_enrollment = this.vehicles[i].enrollment;
				}
			},
			addArk(){
				this.$root.$emit('arks.add');
			},
			/*addNewArk(ark){
				this.arks.push(ark);
			}*/
		}
	}
</script>