<template>
    <div>
        <div class="alert alert-danger" v-if="!contract.id">
            Debes guardar el contrato antes de poder rellenar la documentación
        </div>
        <div v-if="contract.id">
            <div class="row">
                <div class="col-12 col-lg-6">
                    <legend class="text-center">Documentos / Autorizaciones para firmar </legend>
                    <div class="row">
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('ecologic_arc')">Certificación de Arca Ecológica</a>
                            <a v-if="documents.ecologic_arc" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.ecologic_arc.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('authorization_pickup_ash')">Autorización Recogida de Cenizas</a>
                            <a v-if="documents.authorization_pickup_ash" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.authorization_pickup_ash.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('healt_authorization')">Autorización Gestión Sanidad</a>
                            <a v-if="documents.healt_authorization" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.healt_authorization.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('authorization_incineration')">Autorización incineración</a>
                            <a v-if="documents.authorization_incineration" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.authorization_incineration.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('authorization_service')">Autorización de servicio</a>
                            <a v-if="documents.authorization_service" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.authorization_service.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('exit_hospital_residence')">Hoja salida Hospital / Residencia</a>
                            <a v-if="documents.exit_hospital_residence" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.exit_hospital_residence.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('civil_register')">Registro Civil</a>
                            <a v-if="documents.civil_register" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.civil_register.id">Ver</a>
                        </div>
                        <div class="col-12 col-xl-6 text-center text-xl-left mb-3">
                            <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('questionary_declaration_death')">Licencia</a>
                            <a v-if="documents.questionary_declaration_death" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.questionary_declaration_death.id">Ver</a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-6">
                    <legend class="text-center">Justificantes</legend>
                    <div class="text-center">
                        <a class="btn btn-info" href="javascript:void(0)" v-on:click="sendDocument('receipt')">Justificante laboral</a>
                    </div>
                    <br>
                    <div class="text-center">
                        <a class="btn btn-info" href="javascript:void(0)" v-on:click="sendDocument('resume')">Parte de datos</a>
                        <a target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/see-resume/' + contract.id">Ver</a>
                    </div>
                    <br>
                    <div class="text-center">
                        <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('receipt_payment_priest')">Recibo Cura Particular</a>
                        <a v-if="documents.receipt_payment_priest" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.receipt_payment_priest.id">Ver</a>
                    </div>
                    <br>
                    <div class="text-center">
                        <a class="btn btn-info" href="javascript:void(0)" v-on:click="signDocument('receipt_payment_insurance')">Recibo de Seguro</a>
                        <a v-if="documents.receipt_payment_insurance" target="_blank" class="ml-2 btn btn-success" v-bind:href="'/contracts-signed-documents/pdf/' + documents.receipt_payment_insurance.id">Ver</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
            'contract',
            'solicitant',
            'documentation'
        ],
        mounted(){
            this.$root.$on('contracts.section.changed.new.documentation',this.organizeDocumentation);
            this.$root.$on('signed-documents.updated', this.updateSignedDocuments);
        },
        data(){
            return {
                documents:{
                    authorization_inhumation: null,
                    authorization_incineration: null,
                    special_power: null,
                    authorization_pickup: null,
                    personal_objects: null,
                    recognision_sheet: null,
                    incineration_solicitude: null,
                    health_delegation: null,
                    no_family_incineration: null,
                    anonymous_exit: null,
                    ash_delivery: null,
                    ash_renunciation: null,
                    niche_renunciation: null,
                    third_party_ash_authorization: null,
                    healt_authorization: null,
                    ecologic_arc: null,
                    authorization_pickup_ash:null,
                    authorization_service: null,
                    exit_hospital_residence: null,
                    receipt_payment_priest: null,
                    receipt_payment_cemetery: null,
                    receipt_payment_insurance: null,
                    civil_register: null
                }
            };
        },
        methods:{
            signDocument(type){
                this.$root.$emit('sign.add',{
                    type: type,
                    contract: this.contract,
                    solicitant: this.solicitant
                });
            },
            sendDocument(type){
                this.$root.$emit('document.send',{
                    contract: this.contract,
                    type: type,
                    documents: this.contract.documents,
                    signed_documents: this.documents
                });
            },
            updateSignedDocuments(signedDocument){
                this.documents[signedDocument.type] = signedDocument;
            },
            organizeDocumentation(){
                for(var i in this.documentation){
                    var type = this.documentation[i].type;
                    this.documents[type] = this.documentation[i];
                }
            }
        }
    }
</script>
