<template>
	<div>
		<div class="alert alert-danger" v-if="!contract.id">
			Debes guardar el contrato antes de poder adjuntar documentos
		</div>
		<div v-if="contract.id">
			<div class="row">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						DNI fallecido (Frontal) 
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.deceased_front" v-on:click="toggleForPrint('deceased_front')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('deceased_front'), 'fa-check-square': markedForPrint('deceased_front')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.deceased_front" v-bind:initial="initialDocuments.deceased_front"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						DNI fallecido (reverso)
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.deceased_back" v-on:click="toggleForPrint('deceased_back')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('deceased_back'), 'fa-check-square': markedForPrint('deceased_back')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.deceased_back" v-bind:initial="initialDocuments.deceased_back"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						DNI solicitante (Frontal)
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.solicitant_front" v-on:click="toggleForPrint('solicitant_front')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('solicitant_front'), 'fa-check-square': markedForPrint('solicitant_front')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.solicitant_front" v-bind:initial="initialDocuments.solicitant_front"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						DNI solicitante (Reverso)
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.solicitant_back" v-on:click="toggleForPrint('solicitant_back')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('solicitant_back'), 'fa-check-square': markedForPrint('solicitant_back')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.solicitant_back" v-bind:initial="initialDocuments.solicitant_back"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Autorización
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.authorizations" v-on:click="toggleForPrint('authorizations')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('authorizations'), 'fa-check-square': markedForPrint('authorizations')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.authorizations" v-bind:initial="initialDocuments.authorizations"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Recibo bancario
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.bank_receipt" v-on:click="toggleForPrint('bank_receipt')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('bank_receipt'), 'fa-check-square': markedForPrint('bank_receipt')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.bank_receipt" v-bind:initial="initialDocuments.bank_receipt"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Certificado médico (Frontal)
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.medical_certification" v-on:click="toggleForPrint('medical_certification')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('medical_certification'), 'fa-check-square': markedForPrint('medical_certification')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.medical_certification" v-bind:initial="initialDocuments.medical_certification"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Certificado médico (Reverso)
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.medical_certification_back" v-on:click="toggleForPrint('medical_certification_back')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('medical_certification_back'), 'fa-check-square': markedForPrint('medical_certification_back')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.medical_certification_back" v-bind:initial="initialDocuments.medical_certification_back"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Título / Delegos
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.title" v-on:click="toggleForPrint('title')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('title'), 'fa-check-square': markedForPrint('title')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.title" v-bind:initial="initialDocuments.title"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Licencia frontal
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.license_frontal" v-on:click="toggleForPrint('license_frontal')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('license_frontal'), 'fa-check-square': markedForPrint('license_frontal')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.license_frontal" v-bind:initial="initialDocuments.license_frontal"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Licencia trasera
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.license_back" v-on:click="toggleForPrint('license_back')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('license_back'), 'fa-check-square': markedForPrint('license_back')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.license_back" v-bind:initial="initialDocuments.license_back"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="text-right" v-if="toPrint.length > 0">
				<a class="btn btn-primary" target="_blank" v-bind:href="base + '/print-documents/' + contract.id + '?print=' + getDocumentsToPrint()">Imprimir</a>
			</div>
			<hr />
			<legend class="text-center">Documentación adicional</legend>
			<div class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Adicional 1
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.aditional_1" v-on:click="toggleForPrint('aditional_1')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('aditional_1'), 'fa-check-square': markedForPrint('aditional_1')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_1" v-bind:initial="initialDocuments.aditional_1"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Adicional 2
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.aditional_2" v-on:click="toggleForPrint('aditional_2')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('aditional_2'), 'fa-check-square': markedForPrint('aditional_2')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_2" v-bind:initial="initialDocuments.aditional_2"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Adicional 3
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.aditional_3" v-on:click="toggleForPrint('aditional_3')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('aditional_3'), 'fa-check-square': markedForPrint('aditional_3')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_3" v-bind:initial="initialDocuments.aditional_3"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Adicional 4
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.aditional_4" v-on:click="toggleForPrint('aditional_4')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('aditional_4'), 'fa-check-square': markedForPrint('aditional_4')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_4" v-bind:initial="initialDocuments.aditional_4"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Adicional 5
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.aditional_1" v-on:click="toggleForPrint('aditional_1')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('aditional_1'), 'fa-check-square': markedForPrint('aditional_1')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_5" v-bind:initial="initialDocuments.aditional_5"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Adicional 6
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.aditional_6" v-on:click="toggleForPrint('aditional_6')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('aditional_6'), 'fa-check-square': markedForPrint('aditional_6')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.aditional_6" v-bind:initial="initialDocuments.aditional_6"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="text-right" v-if="toPrint.length > 0">
				<a class="btn btn-primary" target="_blank" v-bind:href="base + '/print-documents/' + contract.id + '?print=' + getDocumentsToPrint()">Imprimir</a>
			</div>
			<hr />
			<legend class="text-center">Suplidos</legend>
			<div class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Suplidos 1
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.supplied_1" v-on:click="toggleForPrint('supplied_1')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('supplied_1'), 'fa-check-square': markedForPrint('supplied_1')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_1" v-bind:initial="initialDocuments.supplied_1"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Suplidos 2
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.supplied_2" v-on:click="toggleForPrint('supplied_2')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('supplied_2'), 'fa-check-square': markedForPrint('supplied_2')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_2" v-bind:initial="initialDocuments.supplied_2"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Suplidos 3
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.supplied_3" v-on:click="toggleForPrint('supplied_3')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('supplied_3'), 'fa-check-square': markedForPrint('supplied_3')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_3" v-bind:initial="initialDocuments.supplied_3"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Suplidos 4
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.supplied_4" v-on:click="toggleForPrint('supplied_4')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('supplied_4'), 'fa-check-square': markedForPrint('supplied_4')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_4" v-bind:initial="initialDocuments.supplied_4"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Suplidos 5
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.supplied_5" v-on:click="toggleForPrint('supplied_5')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('supplied_5'), 'fa-check-square': markedForPrint('supplied_5')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_5" v-bind:initial="initialDocuments.supplied_5"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Suplidos 6
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.supplied_6" v-on:click="toggleForPrint('supplied_6')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('supplied_6'), 'fa-check-square': markedForPrint('supplied_6')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.supplied_6" v-bind:initial="initialDocuments.supplied_6"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="text-right" v-if="toPrint.length > 0">
				<a class="btn btn-primary" target="_blank" v-bind:href="base + '/print-documents/' + contract.id + '?print=' + getDocumentsToPrint()">Imprimir</a>
			</div>
			<hr v-if="user_role == 'admin'"/>
			<legend v-if="user_role == 'admin'" class="text-center">Administración</legend>
			<div v-if="user_role == 'admin'" class="row mt-3">
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Administración 1
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.admin_1" v-on:click="toggleForPrint('admin_1')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('admin_1'), 'fa-check-square': markedForPrint('admin_1')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_1" v-bind:initial="initialDocuments.admin_1"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Administración 2
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.admin_2" v-on:click="toggleForPrint('admin_2')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('admin_2'), 'fa-check-square': markedForPrint('admin_2')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_2" v-bind:initial="initialDocuments.admin_2"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Administración 3
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.admin_3" v-on:click="toggleForPrint('admin_3')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('admin_3'), 'fa-check-square': markedForPrint('admin_3')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_3" v-bind:initial="initialDocuments.admin_3"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Administración 4
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.admin_4" v-on:click="toggleForPrint('admin_4')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('admin_4'), 'fa-check-square': markedForPrint('admin_4')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_4" v-bind:initial="initialDocuments.admin_4"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Administración 5
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.admin_5" v-on:click="toggleForPrint('admin_5')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('admin_5'), 'fa-check-square': markedForPrint('admin_5')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_5" v-bind:initial="initialDocuments.admin_5"></contract-form-documentation-file-component>
				</div>
				<div class="col-6 col-md text-center">
					<small class="d-block mb-3">
						Administración 6
						<a href="javascript:void(0)" v-if="initialDocuments && initialDocuments.admin_6" v-on:click="toggleForPrint('admin_6')">
							<i class="fa" v-bind:class="{'fa-square': !markedForPrint('admin_6'), 'fa-check-square': markedForPrint('admin_6')}"></i>
						</a>
					</small>
					<contract-form-documentation-file-component accept="image/*,application/pdf" v-model="documents.admin_6" v-bind:initial="initialDocuments.admin_6"></contract-form-documentation-file-component>
				</div>
			</div>
			<div class="text-right" v-if="user_role == 'admin' && toPrint.length > 0">
				<a class="btn btn-primary" target="_blank" v-bind:href="base + '/print-documents/' + contract.id + '?print=' + getDocumentsToPrint()">Imprimir</a>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		props: ['contract'],
		mounted(){
			this.$root.$on('form.initialize.documents', this.initializeDocuments);
		},
		data(){
			return {
				base: window.urls['contracts.index'],
				front: null,
				user_role: user_role,
				toPrint: [],
				documents: {
                    deceased_front: null,
                    deceased_back: null,
                    solicitant_front: null,
                    solicitant_back: null,
                    authorization: null,
                    bank_receipt: null,
                    medical_certification: null,
                    medical_certification_back: null,
                    title: null,
                    license_frontal: null,
                    license_back: null,
                    aditional_1: null,
                    aditional_2: null,
                    aditional_3: null,
                    aditional_4: null,
                    aditional_5: null,
                    aditional_6: null,
					supplied_1: null,
					supplied_2: null,
					supplied_3: null,
					supplied_4: null,
					supplied_5: null,
					supplied_6: null
                },
                initialDocuments: {
                	deceased_front: null,
                    deceased_back: null,
                    solicitant_front: null,
                    solicitant_back: null,
                    authorization: null,
                    bank_receipt: null,
                    medical_certification: null,
                    medical_certification_back: null,
                    title: null,
                    license_frontal: null,
                    license_back: null,
                    aditional_1: null,
                    aditional_2: null,
                    aditional_3: null,
                    aditional_4: null,
                    aditional_5: null,
                    aditional_6: null,
					supplied_1: null,
					supplied_2: null,
					supplied_3: null,
					supplied_4: null,
					supplied_5: null,
					supplied_6: null
                }
			};
		},
		watch: {
			documents: {
				deep: true,
                handler(data){
					this.$root.$emit('contracts.section.set.documents',this.documents);
				}
			}
		},
		methods: {
			initializeDocuments(data){
				this.toPrint = [];
				if(data.documents){
					this.initialDocuments = data.documents;
				}
			},
			toggleForPrint(filename){
				var index = this.toPrint.indexOf(filename);
				if(index == -1)
					this.toPrint.push(filename);
				else
					this.toPrint.splice(index, 1);
			},
			markedForPrint(filename){
				return this.toPrint.indexOf(filename) != -1;
			},
			getDocumentsToPrint(){
				return this.toPrint.join(',');
			}
		}
	}
</script>