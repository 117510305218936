<template>
	<div class="modal fade action-sheet opax" id="inoutModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-lg" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Registrar entrada/salida</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
					<p v-if="inout.date">Se registrará una entrada para el día {{ inout.date|dayFormat }} a las {{ inout.date|hourFormat }}</p>
				    <div class="row">
				        <div class="col-12 col-md-6">
                            <button class="btn w-100" v-bind:class="{'btn-default':inout.type != 'in','btn-success':inout.type == 'in'}" v-bind:disabled="saving" v-on:click="setType('in')"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Entrada</button>
				        </div>
                        <div class="col-12 col-md-6 mt-md-0 mt-3">
                            <button class="btn w-100" v-bind:class="{'btn-default':inout.type != 'out','btn-success':inout.type == 'out'}" v-bind:disabled="saving" v-on:click="setType('out')"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Salida</button>
                        </div>
                        <div class="col-12 mt-3" v-show="inout.type">
                            <label>¿Algún comentario?</label>
                            <textarea v-model="inout.comments" class="form-control" placeholder="Comentario"></textarea>
                        </div>
				    </div>
				</div>
				<div class="modal-footer">
					<a v-show="path != base" class="btn btn-info" v-bind:href="base">Histórico</a>
                    <div class="ml-auto">
    				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Registrar</button>
    				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            if(!this.isAdmin || this.section == 'home')
                this.$root.$on('inout.add',this.openModal);
        },
        data(){
            return {
                section: window.location.pathname.replace("/",""),
                isAdmin: user_role == 'admin',
            	base: window.urls['inout.index'],
            	path: "/" + window.location.pathname.replace("/",""),
                saving:false,
                inout:{
                    user_id: null,
                    type: null,
                    date: null,
                    comments: null,
                },
                errors: {
                	type: false
                }
            }
        },
        filters: {
        	dayFormat(input){
        		return moment(input).format('DD/MM/YYYY');
        	},
        	hourFormat(input){
        		return moment(input).format('HH:mm');
        	}
        },
        methods:{
        	openModal(data){
        		if(data==undefined){
        			this.inout={
                        user_id: id_user,
	                	type: null,
                        date: moment().format('YYYY-MM-DD HH:mm:ss'),
                        comments: null,
	                };
        		}
        		else{
        			this.inout={
                        id: data.id,
                        user_id: data.id_user,
        				type: data.type,
                        date: data.date,
                        comments: data.comments
	                };
        		}
        		this.saving=false;
        		this.resetErrors();
        		$('#inoutModal').modal({'backdrop':'static'});
        	},
            setType(type){
                this.inout.type=type;
            },
          	resetErrors(){
                this.errors={
                    type: false,
                };
            },

            validate(){
                var valid = true;
                this.errors.type = this.inout.type == null || this.inout.type == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Debes indicar si estás registrando una entrada o una salida',
                    'warning'
                );
            },
            save(){
                var url = this.base;
                var vm = this;
                if(this.inout.id)
                	url += '/' + this.inout.id;
                this.saving = true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.inout,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('inout.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Has fichado correctamente',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                });
            },
            cancel(){
            	$('#inoutModal').modal('hide');
            }
        }
    }
</script>
