<template>
	<div class="modal fade action-sheet opax" id="sendDocumentModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ titles[type] }}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12" v-if="type == 'receipt'">
                            <label>Receptor</label>
                            <select class="form-control" v-model="details.is_solicitant">
                                <option value="1">Solicitante</option>
                                <option value="0">Otro</option>
                            </select>
                        </div>
                        <div class="col-12" v-if="type == 'receipt' && details.is_solicitant === '0'">
                            <label>Nombre completo</label>
                            <input type="text" class="form-control" v-model="details.name" v-bind:class="{'is-invalid': errors.name}" placeholder="Nombre completo"/>
                        </div>
                        <div class="col-12" v-if="type == 'receipt' && details.is_solicitant === '0'">
                            <label>Parentesco</label>
                            <input type="text" class="form-control" v-model="details.relation" v-bind:class="{'is-invalid': errors.relation}" placeholder="Parentesco"/>
                        </div>
                        <div class="col-12">
                            <label>Email *</label>
                            <input spellcheck="true" type="email" class="form-control" v-model="details.email" placeholder="Email" v-bind:class="{'is-invalid':errors.email}"/>
                        </div>
                        <div class="col-12">
                            <label>Comentarios *</label>
                            <textarea class="form-control" v-model="details.comments" placeholder="Comentarios"></textarea>
                        </div>
                        <div class="col-12" v-if="type == 'resume' && documentsToShow.length > 0">
                            <label>Documentos</label>
                            <div>
                                <button class="mr-2 mb-2 btn" v-bind:class="isSetForSend(d.key)" v-on:click="setDocumentToSend(d.key)" v-for="d in documentsToShow">
                                    {{ documentTitles[d.key] }}
                                </button>
                            </div>
                            <div>
                                <button class="mr-2 mb-2 btn" v-bind:class="isSignedSetForSend(sd.key)" v-on:click="setSignedDocumentToSend(sd.key)" v-for="sd in signedDocumentsToShow">
                                    {{ signedDocumentTitles[sd.key] }}
                                </button>
                            </div>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Enviar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('document.send',this.openModal);
            this.$root.$on('form.initialize.documents', this.initializeDocuments);
            this.$root.$on('form.update.documents',this.setDocuments);
        },
        data(){
            return {
            	base: window.urls['contracts-signed.index'],
                type: 'receipt',
                saving: false,
                documents: [],
                documentsToShow: [],
                signedDocuments: [],
                signedDocumentsToShow: [],
                titles: {
                    'receipt': 'Justificante de asistencia',
                    'resume': 'Parte de datos',
                },
                documentTitles: {
                    deceased_front: 'DNI fallecido (Frontal)',
                    deceased_back: 'DNI fallecido (reverso)',
                    solicitant_front: 'DNI solicitante (Frontal)',
                    solicitant_back: 'DNI solicitante (Reverso)',
                    authorizations: 'Autorización',
                    bank_receipt: 'Recibo bancario',
                    medical_certification: 'Certificado médico (Frontal)',
                    medical_certification_back: 'Certificado médico (Reverso)',
                    title: 'Título / Delegos',
                    license_frontal: 'Licencia frontal',
                    license_back: 'Licencia trasera',
                    aditional_1: 'Adicional 1',
                    aditional_2: 'Adicional 2',
                    aditional_3: 'Adicional 3',
                    aditional_4: 'Adicional 4',
                    aditional_5: 'Adicional 5',
                    aditional_6: 'Adicional 6',
                    supplied_1: 'Suplidos 1',
                    supplied_2: 'Suplidos 2',
                    supplied_3: 'Suplidos 3',
                    supplied_4: 'Suplidos 4',
                    supplied_5: 'Suplidos 5',
                    supplied_6: 'Suplidos 6'
                },
                signedDocumentTitles: {
                    ecologic_arc: 'Certificación de Arca Ecológica',
                    authorization_pickup_ash: 'Autorización Recogida de Cenizas',
                    healt_authorization: 'Autorización Gestión Sanidad',
                    authorization_incineration: 'Autorización incineración',
                    authorization_service: 'Autorización de servicio',
                    exit_hospital_residence: 'Hoja salida Hospital / Residencia',
                    authorization_pickup_ash: 'Autorización recogida cenizas',
                    civil_register: 'Registro Civil',
                    questionary_declaration_death: 'Cuestionario para la declaración de la defunción',
                    receipt_payment_priest: 'Recibo Cura Particular',
                    receipt_payment_insurance: 'Recibo de Seguro'
                },
                details:{
                    id_contract: null,
                    is_solicitant: null,
                    name: null,
                    relation: null,
                    is_solicitant: null,
                    email: null,
                    comments: null,
                },
                errors: {
                    email: null,
                    name: null,
                    relation: null
                },
            }
        },
        methods:{
            setDocuments(data){
                this.documents = data;
            },
            initializeDocuments(data){
                if(data.documents){
                    this.documents = data.documents;
                }
                else
                    this.documents = [];
            },
            isSetForSend(key){
                var index = this.details.documents.indexOf(key);
                var finalClass = (index == -1)?'btn-danger':'btn-success';
                return finalClass;
            },
            isSignedSetForSend(key){
                var index = this.details.signed_documents.indexOf(key);
                var finalClass = (index == -1)?'btn-danger':'btn-success';
                return finalClass;
            },
            setDocumentToSend(key){
                var index = this.details.documents.indexOf(key);
                if(index != -1)
                    this.details.documents.splice(index, 1);
                else
                    this.details.documents.push(key);
            },
            setSignedDocumentToSend(key){
                var index = this.details.signed_documents.indexOf(key);
                if(index != -1)
                    this.details.signed_documents.splice(index, 1);
                else
                    this.details.signed_documents.push(key);
            },
            processDocuments(){
                this.documentsToShow = [];
                for(var i in this.documents){
                    if(this.documentTitles[i] && this.documents[i] != null){
                        this.documentsToShow.push({
                            key: i,
                            document: this.documents[i]
                        });
                    }
                }
            },
            processSignedDocuments(){
                this.signedDocumentsToShow = [];
                for(var i in this.signedDocuments){
                    if(this.signedDocumentTitles[i] && this.signedDocuments[i] != null){
                        this.signedDocumentsToShow.push({
                            key: i,
                            document: this.signedDocuments[i]
                        });
                    }
                }
                console.log(this.signedDocumentsToShow);
            },
        	openModal(data){
                this.details = {
                    id_contract: data.contract.id,
                    name: null,
                    relation: null,
                    is_solicitant: null,
                    email: null,
                    comments: null,
                    documents: [],
                    signed_documents: [],
                };
                this.signedDocuments = data.signed_documents;
                this.processDocuments();
                this.processSignedDocuments();
                this.type = data.type;
                this.resetErrors();
        		$('#sendDocumentModal').modal({
                    'backdrop': 'static'
                });
        		var vm = this;
        	},
            resetErrors(){
                this.errors = {
                    email: false
                };
            },
            validate(){
                var valid = true;
                this.errors.email = this.details.email == null || this.details.email == "";
                if(this.type == 'receipt' && this.details.is_solicitant === 0){
                    this.errors.name = this.details.name == null || this.details.name == "";
                    this.errors.relation = this.details.relation == null || this.details.relation == "";
                }
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },

            save(){
                if(!this.validate())
                    return;
                var url = this.base + '/' + this.type;
                var vm = this;
                this.saving = true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.details,
                    responseType: 'json',
                }).then(function(response){
                    var message = 'Se ha enviado el documento'
                    vm.saving = false;
                    vm.cancel();
                    vm.$swal(
                        'Correcto',
                        message,
                        'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = "El correo no se ha podido enviar";
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#sendDocumentModal').modal('hide');
            }
        }
    }
</script>
