<template>
	<div class="modal fade action-sheet opax" id="changeNumberModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-sm" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Editar numeración</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body" v-if="contract">
					<div class="row">
						<div class="form-group col-12">
							<label>Nº de contrato</label>
							<input class="form-control" type="text" v-model="contract.number" placeholder="Nº de contrato" />
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
					<button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	export default{
		mounted() {
			this.$root.$on('contracts.edit.number',this.openModal);
		},
		data(){
			return {
				base: window.urls['contracts.index'],
				saving: false,
				contract: null
			};
		},
		methods:{
			openModal(data){
				this.contract = JSON.parse(JSON.stringify(data));
				$('#changeNumberModal').modal({'backdrop':'static'});
			},
			save(){
				var vm = this;
				var url = this.base + '/' + this.contract.id;
				this.saving = true;
				axios({
	                method: 'post',
	                url: url,
	                data: {
	                    contract: {
	                    	id: this.contract.id,
	                    	number: this.contract.number
	                    }
	                },
	                responseType: 'json',
	            }).then(function(response){
	                vm.saving=false;
	                vm.cancel();
	                vm.$root.$emit('contracts.updated');
	            })
			},
			cancel(){
            	$('#changeNumberModal').modal('hide');
            }
		}
	}
</script>