<template>
    <div class="row mb-3">
        <div class="col-12">
            <p>Mostrando: {{ results.count }} de {{ results.total }}</p>
        </div>
        <div class="col-12 col-md">
            <div class="row" v-if="searching">
                <div class="form-user col-12 col-md-3" v-if="role">
                    <label>Trabajador</label>
                    <v-select v-on:search="searchWorkers" v-bind:options="workers" v-model="worker" v-on:input="selectWorker" :filter="customSearch">
                        <template slot="spinner">
                            <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
                        </template>
                        <template #no-options="{ search, searching, loading }">
                            Ninguna coincidencia
                        </template>
                    </v-select>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Tipo</label>
                    <select class="form-control" v-model="filter.type" v-on:change="search">
                        <option value="">Tipo</option>
                        <option value="in">Entrada</option>
                        <option value="out">Salida</option>
                    </select>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Periodo</label>
                    <datepicker v-model="dateRanges" style="display: block;width: 100%" input-class="form-control"  v-bind:lang="lang" format="DD/MM/YYYY" type="date" range placeholder="Periodo" v-on:change="changeRange"></datepicker>
                </div>
                <div class="col-12 text-right mt-3">
                    <button class="my-auto btn btn-primary" v-on:click="search()" v-bind:disabled="loading">
                        <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                        {{ loading?'Buscando':'Buscar' }}
                    </button>
                    <button class="my-auto btn btn-warning" v-on:click="clean()">Limpiar</button>
                    <button class="my-auto btn btn-danger" v-on:click="toggleSearch()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
    export default {
        components: { Datepicker },
        mounted() {
            this.$root.$on('inout.loaded',this.tableLoaded);
            this.$root.$on('inout.filter',this.toggleSearch);
            this.$root.$on('inout.report',this.downloadExcel);
        },
        data(){
            var startOfMonth = moment().startOf('month');
            var endOfMonth   = moment().endOf('month');
            return {
                base:window.urls['inout.index'],
                baseWorkers:window.urls['users.index'],
                workersTimeout: null,
                role: user_role,
                spinner:false,
                searching:true,
                loading:true,
                worker:null,
                workers:[],
                clients:[],
                client:null,
                dateRanges:[
                    startOfMonth.toDate(),
                    endOfMonth.toDate()
                ],
                filter:{
                    type: "",
                    from: startOfMonth.format('YYYY-MM-DD') + ' 00:00:00',
                    to: endOfMonth.format('YYYY-MM-DD') + ' 23:59:59'
                },
                results:{
                    total:0,
                    count: 0
                },
                lang: {
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
            }
        },
        methods:{
            tableLoaded(data){
                this.results = data;
                this.loading = false;
            },
            toggleSearch(){
                this.searching = !this.searching;
            },
            search(){
                this.loading=true;
                this.$root.$emit('inout.search',this.filter);
            },
            changeRange(values){
                var from = null;
                var to = null;
                if(values && values[0] != null && values[1] != null){
                    from = moment(values[0]).format('YYYY-MM-DD');
                    to = moment(values[1]).format('YYYY-MM-DD');
                }
                this.filter.from = from;
                this.filter.to = to;
                this.search();
            },
            downloadExcel(){
                var json=JSON.stringify(this.filter)
                window.open(this.base + '/report?query=' + json, '_blank');
            },
            clean(){
                for(var i in this.filter)
                    this.filter[i] = "";
                this.search();
            },
            customSearch(options, search) {
              return options;
            },
            formatWorkerLabel(worker){
                return worker.name + ' ' + worker.lastname;
            },
            searchWorkers(search){
                var vm = this;
                if(search == null){
                    clearInterval(this.workersTimeout);
                    return ;
                }
                if(this.workersTimeout != null)
                    clearInterval(this.workersTimeout);
                this.workersTimeout = setInterval(function(){
                    var query = {
                        search: search
                    }
                    axios({
                        method: 'get',
                        url: vm.baseWorkers + '/table?limit=20&query=' + JSON.stringify(query),
                        responseType: 'json',
                    }).then(function(response){
                        var workers = response.data.data;
                        vm.workers = [];
                        for(var i = 0; i<workers.length; i++){
                            vm.workers.push({
                                code:workers[i].id,
                                label:vm.formatWorkerLabel(workers[i])
                            })
                        }
                    })
                    .catch(function (error) {
                        console.log()
                        vm.getting = false;
                        vm.$swal(
                          '¡Ups!',
                          'Parece que algo fue mal',
                          'error'
                        );  
                    }); 
                }, 1500);
            },
            selectWorker(value){
                if(value != null)
                    this.filter.user_id = value.code;
                else this.filter.user_id = null;
                this.search();
            },
        }
    }
</script>
