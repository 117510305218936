<template>
	<div class="card-tools">
        <a v-if="columns" class="btn btn-primary btn-sm" v-on:click="config()">
            <slot name="search-label">
                <i class="fas fa-list"></i> Columnas
            </slot>
        </a>
        <a v-if="optionimport" class="ml-1 btn btn-primary btn-sm" v-on:click="openImportForm()">
            <slot name="import-label">
                <i class="fas fa-upload"></i> Importar
            </slot>
        </a>
        <a v-if="optionreport" class="ml-1 btn btn-primary btn-sm" v-on:click="downloadReport()">
            <slot name="report-label">
                <i class="fas fa-download"></i> Informe
            </slot>
        </a>
		<a v-if="optionsearch" class="ml-1 btn btn-primary btn-sm" v-on:click="search()">
            <slot name="search-label">
                <i class="fas fa-search"></i> Buscar
            </slot>
		</a>
        <a v-if="optionadd" class="ml-1 btn btn-primary btn-sm" v-on:click="add()">
            <slot name="add-label">
                <i class="fas fa-plus"></i> Añadir
            </slot>
        </a>
	</div>
</template>
<script>
    export default {
        props:['columns','section','optionreport','optionimport','optionadd','optionsearch'],
        methods:{
            config(){
                var event=this.section!=null?this.section+'.config':'config';
                this.$root.$emit(event);
            },
            openImportForm(){
                var event=this.section!=null?this.section+'.import':'import';
                this.$root.$emit(event);
            },
            downloadReport(){
                var event=this.section!=null?this.section+'.report':'report';
                this.$root.$emit(event);
            },
            search(){
                var event=this.section!=null?this.section+'.filter':'filter';
            	this.$root.$emit(event);
            },
            add(){
                var event=this.section!=null?this.section+'.add':'add';
            	this.$root.$emit(event);
            },
        }
    }
</script>
