<template>
	<div class="modal fade action-sheet opax" id="contactModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Ver mensaje</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Estado *</label>
                            <select class="form-control" v-model="contact.status" v-bind:class="{'is-invalid':errors.company_web}">
                                <option value="pending">Por contactar</option>
                                <option value="contacted">Enviado</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contact.name" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Apellidos *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="contact.surname" placeholder="Apellidos" v-bind:class="{'is-invalid':errors.surname}"/>
                        </div>
                        <div class="col-12">
                            <label>Correo electrónico *</label>
                            <input spellcheck="true" type="email" class="form-control" v-model="contact.email"  placeholder="Correo electrónico" v-bind:class="{'is-invalid':errors.email}"/>
                        </div>
                        <div class="col-12">
                            <label>Teléfono</label>
                            <input type="text" class="form-control" v-model="contact.phone" />
                        </div>
                        <div class="col-12">
                            <label>Cómo nos conoció</label>
                            <select class="form-control" v-model="contact.how_meet" v-bind:class="{'is-invalid':errors.how_meet}">
                                <option value="">Cómo nos conoció</option>
                                <option value="google">Google</option>
                                <option value="social">Redes Sociales</option>
                                <option value="whatsapp">Whatsapp</option>
                                <option value="recomendation">Recomendación</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Mensaje *</label>
                            <textarea class="form-control" v-model="contact.message" v-bind:class="{'is-invalid':errors.message}"></textarea>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
                    <button type="button" class="btn btn-primary" v-on:click="budget();">Presupuesto</button>
                    <div class="ml-auto">
                        <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
    				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cerrar</button>
                    </div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contacts.add',this.openModal);
            this.$root.$on('contacts.edit',this.openModal);
        },
        data(){
            return {
            	base: window.urls['contacts.index'],
                roles:[],
                saving: false,
                contact:{
                    name: null,
                    lastname: null,
                    email: null
                },
                errors: {
                    type: false,
                    name: false,
                    phone: false,
                    mobile_phone: false,
                    email: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.contact = {
                        id: data.id,
                        name: data.name,
                        status: data.status,
                        surname: data.surname,
                        email: data.email,
                        phone: data.phone,
                        message: data.message,
                        how_meet: data.how_meet
                    };
                }else{
                    this.contact = {
                        name: null,
                        status: 'pending',
                        surname: null,
                        email: null,
                        phone: null,
                        message: null,
                        how_meet: ""
                    };
                }
                this.resetErrors();
        		$('#contactModal').modal({'backdrop':'static'});
        	},
            budget(){
                this.$root.$emit('contacts.budget.edit',this.contact);
            },
            resetErrors(){
                this.errors = {
                    status: false,
                    surname: false,
                    email: false,
                    message: false,
                };
            },
            validate(){
                var valid = true;
                this.errors.status = this.contact.status == null || this.contact.status == "";
                this.errors.surname = this.contact.surname == null || this.contact.surname == "";
                this.errors.email = this.contact.email == null || this.contact.email == "";
                this.errors.message = this.contact.message == null || this.contact.message == "";

                for(var i in this.errors)
                    valid &= !this.errors[i];

                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.contact.id)
                    url += '/' + this.contact.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.contact,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('contacts.updated');
                    vm.cancel();
                    vm.$swal(
                        'Correcto',
                        'Se ha guardado el contacto',
                        'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#contactModal').modal('hide');
            }
        }
    }
</script>
