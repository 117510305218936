<template>
	<div class="modal fade action-sheet opax" id="clientModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-lg" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ client.id?'Editar cliente':'Nuevo cliente'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md-8">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Tipo de cliente *</label>
                            <select class="form-control" v-model="client.type" v-bind:class="{'is-invalid':errors.type}">
                                <option value="residence">Residencia</option>
                                <option value="hospital">Clínica / Hospital</option>
                                <option value="other">Otro</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label>Email *</label>
                            <input spellcheck="true" type="email" class="form-control" v-model="client.email" placeholder="Email" v-bind:class="{'is-invalid':errors.email}"/>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Teléfono *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.phone" placeholder="Teléfono"  v-bind:class="{'is-invalid':errors.phone}"/>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Teléfono móvil *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.mobile_phone" placeholder="Teléfono 2" v-bind:class="{'is-invalid':errors.mobile_phone}"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label>Dirección</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.address" placeholder="Dirección" v-bind:class="{'is-invalid':errors.address}"/>
                        </div>
                        <div class="col-12 col-md-3">
                            <label>Código postal</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.postcode" placeholder="Código postal" v-bind:class="{'is-invalid':errors.postcode}"/>
                        </div>
                        <div class="col-12 col-md-3">
                            <label>Provincia</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.state" placeholder="Provincia" v-bind:class="{'is-invalid':errors.state}"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <label>Empresa</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.company_name" placeholder="Dirección" v-bind:class="{'is-invalid':errors.company_name}"/>
                        </div>
                        <div class="col-12 col-md-6">
                            <label>Web de la empresa</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="client.company_web" placeholder="Código postal" v-bind:class="{'is-invalid':errors.company_web}"/>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <label>Notas</label>
                            <textarea v-model="client.comments" class="form-control"></textarea>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('clients.add',this.openModal);
            this.$root.$on('clients.edit',this.openModal);
        },
        data(){
            return {
            	base: window.urls['clients.index'],
                countries:[],
                saving: false,
                birthDate: null,
                isValidCif: true,
                client:{
                    id: null,
                    type: null,
                    name: null,
                    phone: null,
                    mobile_phone: null,
                    email: null,
                    address: null,
                    postcode: null,
                    state: null,
                    company_name: null,
                    company_web: null,
                    comments: null,
                },
                errors: {
                    type: false,
                    name: false,
                    phone: false,
                    mobile_phone: false,
                    email: false,
                },
                lang: {
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
            }
        },
        methods:{
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
        	openModal(data){
                if(data){
                    this.client = {
                        id: data.id,
                        type: data.type,
                        name: data.name,
                        phone: data.phone,
                        mobile_phone: data.mobile_phone,
                        email: data.email,
                        address: data.address,
                        postcode: data.postcode,
                        state: data.state,
                        company_name: data.company_name,
                        company_web: data.company_web,
                        comments: data.comments
                    };
                }else{
                    this.client = {
                        type: null,
                        name: null,
                        phone: null,
                        mobile_phone: null,
                        email: null,
                        address: null,
                        postcode: null,
                        state: null,
                        company_name: null,
                        company_web: null,
                        comments: null,
                    };
                }
                this.resetErrors();
        		$('#clientModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors = {
                    type: false,
                    name: false,
                    phone: false,
                    mobile_phone: false,
                    email: false,
                    postcode: false,
                    state: false
                };
            },
            firstLetterOnUpperCase(field){
                if(this.client[field] == null)
                    return;
                var aux=this.client[field].split(" ");
                var final = "";
                for(var i = 0;i < aux.length; i++){
                    final += aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i < aux.length - 1) final += " ";
                }
                this.client[field] = final;
            },
            validate(){
                var valid = true;

                this.errors.type = this.client.type == null || this.client.type == "";
                this.errors.name = this.client.name == null || this.client.name == "";
                this.errors.phone = this.client.phone == null || this.client.phone == "";
                this.errors.mobile_phone = this.client.mobile_phone == null || this.client.mobile_phone == "";
                this.errors.email = this.client.email == null || this.client.email == "";

                for(var i in this.errors)
                    valid &= !this.errors[i];

                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.client.id) url += '/' + this.client.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.client,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('clients.updated');
                    vm.cancel();
                    vm.$swal(
                        'Correcto',
                        'Se ha guardado al cliente',
                        'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#clientModal').modal('hide');
            }
        }
    }
</script>
