<template>
	<div class="modal fade action-sheet opax" id="arkModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ ark.id?'Editar arca':'Nuevo arca'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Código *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="ark.code" placeholder="Código" v-bind:class="{'is-invalid':errors.code}"/>
                        </div>
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="ark.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Gama</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="ark.gamma" placeholder="Gama" v-bind:class="{'is-invalid':errors.gamma}"/>
                        </div>
                        <div class="col-12">
                            <label>Precio</label>
                            <input spellcheck="true" type="number" min="0" step="0.01" class="form-control" v-model="ark.price" placeholder="Precio" v-bind:class="{'is-invalid':errors.price}"/>
                        </div>
                        <div class="col-12">
                            <label>Ecológico</label>
                            <select class="form-control" v-model="ark.eco" v-bind:class="{'is-invalid':errors.price}">
                                <option value="1">Sí</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('arks.add',this.openModal);
            this.$root.$on('arks.edit',this.openModal);
        },
        data(){
            return {
            	base:window.urls['arks.index'],
                saving: false,
                ark:{
                    code: null,
                    name: null,
                },
                errors: {
                    code: false,
                    name: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.ark={
                        id: data.id,
                        code: data.code,
                        name: data.name,
                        gamma: data.gamma,
                        price: data.price,
                        eco: data.eco
                    };
                }else{
                    this.ark={
                        code: null,
                        name: null,
                        gamma: null,
                        price: null,
                        eco: null
                    };
                }
                this.resetErrors();
        		$('#arkModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    code: false
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.ark[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.ark[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.code = this.ark.code == null || this.ark.code == "";
                
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.ark.id) url += '/' + this.ark.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.ark,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('arks.updated', response.data);
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el arca',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#arkModal').modal('hide');
            }
        }
    }
</script>
