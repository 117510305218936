<template>
	<div class="modal fade action-sheet opax" id="insuranceModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-lg" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ insurance.id?'Editar seguro':'Nuevo seguro'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-md-4 col-12">
                            <label>Razón Social *</label>
                            <input type="text" class="form-control" v-model="insurance.legal_name" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Nombre comercial *</label>
                            <input type="text" class="form-control" v-model="insurance.tradename" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Email *</label>
                            <input type="text" class="form-control" v-model="insurance.email" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Nombre de contacto *</label>
                            <input type="text" class="form-control" v-model="insurance.name" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Primer apellido de contacto *</label>
                            <input type="text" class="form-control" v-model="insurance.surname" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Segundo apellido de contacto *</label>
                            <input type="text" class="form-control" v-model="insurance.lastname" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Dirección de facturación *</label>
                            <input type="text" class="form-control" v-model="insurance.address" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Código postal *</label>
                            <input type="text" class="form-control" v-model="insurance.postcode" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Población *</label>
                            <input type="text" class="form-control" v-model="insurance.city" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Provincia *</label>
                            <input type="text" class="form-control" v-model="insurance.state" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>CIF *</label>
                            <input type="text" class="form-control" v-model="insurance.cif" />
                        </div>
                        <div class="col-md-4 col-12">
                            <label>Teléfono *</label>
                            <input type="text" class="form-control" v-model="insurance.phone_number" />
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('insurances.add',this.openModal);
            this.$root.$on('insurances.edit',this.openModal);
        },
        data(){
            return {
            	base:window.urls['insurances.index'],
                saving: false,
                insurance:{
                    legal_name: null,
                    tradename: null,
                    email:null,
                    name: null,
                    surname: null,
                    lastname:null,
                    address: null,
                    postcode:null,
                    city: null,
                    state: null,
                    cif: null,
                    phone_number: null,
                },
                errors: {
                    legal_name: false,
                    tradename: false,
                    email:false,
                    name: false,
                    surname: false,
                    lastname:false,
                    address: false,
                    postcode:false,
                    city: false,
                    state: false,
                    cif: false,
                    phone_number: false,
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.insurance={
                        id: data.id,
                        legal_name: data.legal_name,
                        tradename: data.tradename,
                        email: data.email,
                        name: data.name,
                        surname: data.surname,
                        lastname: data.lastname,
                        address: data.address,
                        postcode: data.postcode,
                        city: data.city,
                        state: data.state,
                        cif: data.cif,
                        phone_number: data.phone_number
                    };
                }else{
                    this.insurance={
                        legal_name: null,
                        tradename: null,
                        email:null,
                        name: null,
                        surname: null,
                        lastname:null,
                        address: null,
                        postcode:null,
                        city: null,
                        state: null,
                        cif: null,
                        phone_number: null,
                    };
                }
                this.resetErrors();
        		$('#insuranceModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors = {
                    legal_name: false,
                    tradename: false,
                    email:false,
                    name: false,
                    surname: false,
                    lastname:false,
                    address: false,
                    postcode:false,
                    city: false,
                    state: false,
                    cif: false,
                    phone_number: false,
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.insurance[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.insurance[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.legal_name = this.insurance.legal_name == null || this.insurance.legal_name == "";
                this.errors.tradename = this.insurance.tradename == null || this.insurance.tradename == "";              
                this.errors.email = this.insurance.email == null || this.insurance.email == "";
                this.errors.name = this.insurance.name == null || this.insurance.name == "";
                this.errors.surname = this.insurance.surname == null || this.insurance.surname == "";
                this.errors.lastname = this.insurance.lastname == null || this.insurance.lastname == "";
                this.errors.address = this.insurance.address == null || this.insurance.address == "";
                this.errors.postcode = this.insurance.postcode == null || this.insurance.postcode == "";
                this.errors.city = this.insurance.city == null || this.insurance.city == "";
                this.errors.state = this.insurance.state == null || this.insurance.state == "";               
                this.errors.cif = this.insurance.cif == null || this.insurance.cif == "";
                this.errors.phone_number = this.insurance.phone_number == null || this.insurance.phone_number == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url = this.base;
                var vm = this;
                if(this.insurance.id)
                    url += '/' + this.insurance.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.insurance,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('insurances.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el seguro',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#insuranceModal').modal('hide');
            }
        }
    }
</script>
