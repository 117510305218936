<template>
    <div class="row mb-3">
    	<div class="col-12">
    		<p>Mostrando: {{ results.count }} de {{ results.total }}</p>
    	</div>
        <div class="col-12 col-md">
            <div class="row" v-if="searching">
                <div class="form-user col-12 col-md-3">
                    <label>Nombre legal</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Nombre legal" v-model="filter.legal_name" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Nombre comercial</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Nombre comercial" v-model="filter.tradename" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>CIF</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="CIF" v-model="filter.cif" v-on:change="search"/>
                </div>
                <div class="col-12 mt-3 text-right">
                    <button class="my-auto btn btn-primary" v-on:click="search()" v-bind:disabled="loading">
                        <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                        {{ loading?'Buscando':'Buscar' }}
                    </button>
                    <button class="my-auto btn btn-warning" v-on:click="clean()">Limpiar</button>
                    <button class="my-auto btn btn-danger" v-on:click="toggleSearch()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	props:['base'],
        mounted() {
            this.$root.$on('insurances.loaded',this.tableLoaded);
            this.$root.$on('insurances.filter',this.toggleSearch);
        },
        data(){
        	return {
                searching:true,
                loading:true,
                roles:[],
                filter:{
                    legal_name: "",
                    tradename: "",
                    cif: ""
                },
                results:{
                    total:0,
                    count: 0
                },
        	}
	  	},
        methods:{
        	tableLoaded(data){
        		this.results=data;
        		this.loading=false;
        	},
            downloadExcel(){
                var json=JSON.stringify(this.query)
                window.open(this.base+'/download?query='+json, '_blank');
            },
            toggleSearch(){
            	this.searching=!this.searching;
            },
            search(){
                this.loading=true;
                this.$root.$emit('insurances.search',this.filter);
            },
            clean(){
            	for(var i in this.filter)
            		this.filter[i]="";
            	this.search();
            }
        }
    }
</script>
