<template>
	<a href="javascript:void(0);" v-on:click="callAction">
		<slot>Añadir</slot>
	</a>
</template>
<script>
	export default{
		props: ['section'],
		methods: {
			callAction(){
				this.$root.$emit(this.section + '.add')
			}
		}
	}
</script>