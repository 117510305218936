<template>
    <div class="row mb-3">
    	<div class="col-12">
    		<p>Mostrando: {{ results.count }} de {{ results.total }}</p>
    	</div>
        <div class="col-12 col-md">
            <div class="row" v-if="searching">
                <div class="form-user col-12 col-md-3">
                    <label>Nombre</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Nombre" v-model="filter.name" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Categoría</label>
                    <select class="form-control" v-model="filter.id_category" v-on:change="search">
                        <option value="">Todas</option>
                        <option v-for="c in categories" v-bind:value="c.id">{{ c.label }}</option>
                    </select>
                </div>
                <div class="col-12 mt-3 text-right">
                    <button class="my-auto btn btn-primary" v-on:click="search()" v-bind:disabled="loading">
                        <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                        {{ loading?'Buscando':'Buscar' }}
                    </button>
                    <button class="my-auto btn btn-warning" v-on:click="clean()">Limpiar</button>
                    <button class="my-auto btn btn-danger" v-on:click="toggleSearch()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	props:['base'],
        mounted() {
            this.$root.$on('portfolio.loaded',this.tableLoaded);
            this.$root.$on('portfolio.filter',this.toggleSearch);
            this.getCategories();
        },
        beforeMount(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            let type = params.get("type")
            this.filter.type = params.get("type");
            this.type =  params.get("type");
        },
        data(){
        	return {
                baseCategories: window.urls['portfolio.categories.index'],
                searching:true,
                loading:true,
                categories:[],
                type: 'service',
                filter:{
                    id_category: "",
                    type: "service"
                },
                results:{
                    total:0,
                    count: 0
                },
        	}
	  	},
        methods:{
            getCategories(){
                var vm=this;
                var type = {
                    type: this.type
                };
                axios({
                    method: 'get',
                    url: this.baseCategories + '/table?query=' + JSON.stringify(type) + '&orderBy=label&ascending=1',
                    responseType: 'json',
                }).then(function(response){
                    vm.categories=response.data.data;
                })
                .catch(function (error) {
                    vm.getting=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
            },
        	tableLoaded(data){
        		this.results=data;
        		this.loading=false;
        	},
            downloadExcel(){
                var json=JSON.stringify(this.query)
                window.open(this.base+'/download?query='+json, '_blank');
            },
            toggleSearch(){
            	this.searching=!this.searching;
            },
            search(){
                this.loading=true;
                this.$root.$emit('portfolio.search',this.filter);
            },
            clean(){
            	for(var i in this.filter)
            		this.filter[i]="";
            	this.search();
            }
        }
    }
</script>
