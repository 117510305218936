<template>
	<div class="modal fade action-sheet opax" id="signModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title text-uppercase">{{ getTitle() }}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md" v-show="shouldShow('is_solicitant')">
                        	<select class="form-control" v-model="sign.is_solicitant" v-on:change="fillDetails">
                        		<option value="1">Firma solicitante</option>
                        		<option value="0">Otro</option>
                        	</select>
                        	<div class="row">
                                <div class="col-12" v-show="shouldShow('name')">
	                            	<label>Nombre y apellidos *</label>
	                            	<input spellcheck="true" type="text" class="form-control" v-model="sign.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
	                            </div>
                                <div class="col-12 col-md-6" v-show="shouldShow('type_of_document')">
                                    <label>Tipo de documento</label>
                                    <select class="form-control" v-model="sign.type_of_document">
                                        <option value="dni">DNI</option>
                                        <option value="passport">Pasaporte</option>
                                        <option value="other">Otros</option>
                                    </select>
                                </div>
                        		<div class="col-12 col-md-6" v-show="shouldShow('document')">
	                            	<label>Documento identidad *</label>
	                            	<input spellcheck="true" type="text" class="form-control" v-model="sign.document" placeholder="Documento identidad" v-bind:class="{'is-invalid':errors.document}"/>
	                            </div>
                                <div class="col-12 col-md-4" v-show="shouldShow('relation')">
		                            <label>Parentesco</label>
		                            <input spellcheck="true" type="text" class="form-control" v-model="sign.relation" placeholder="Parentesco" v-bind:class="{'is-invalid':errors.relation}"/>
		                        </div>
	                            <div class="col-12" v-show="shouldShow('address')">
                            		<label>Domicilio *</label>
		                            <input spellcheck="true" type="text" class="form-control" v-model="sign.address" placeholder="Domicilio" v-bind:class="{'is-invalid':errors.address}"/>
		                        </div> 
	                            <div class="col-12" v-show="shouldShow('name_civil_register')">
                            		<label>Nombre Registro Civil *</label>
		                            <input spellcheck="true" type="text" class="form-control" v-model="sign.name_civil_register" placeholder="Nombre Registro Civil *" v-bind:class="{'is-invalid':errors.name_civil_register}"/>
		                        </div>
	                            <div class="col-12" v-show="shouldShow('tome_register')">
                            		<label>Tomo *</label>
		                            <input spellcheck="true" type="text" class="form-control" v-model="sign.tome_register" placeholder="Tomo *" v-bind:class="{'is-invalid':errors.tome_register}"/>
		                        </div>
	                            <div class="col-12" v-show="shouldShow('page_register')">
                            		<label>Página *</label>
		                            <input spellcheck="true" type="text" class="form-control" v-model="sign.page_register" placeholder="Página *" v-bind:class="{'is-invalid':errors.page_register}"/>
		                        </div>
	                            <div class="col-12" v-show="shouldShow('observations_register')">
                            		<label>Observaciones *</label>
		                            <input spellcheck="true" type="text" class="form-control" v-model="sign.observations_register" placeholder="Observaciones *" v-bind:class="{'is-invalid':errors.observations_register}"/>
		                        </div>
		                    </div>
                        </div>
                        <div class="col-12 col-md-6" v-show="sign.is_solicitant == 0">
                        	<VueSignaturePad class="form-control" width="100%" height="300px" ref="signaturePad" :options="{ onBegin, onEnd }"/>
                        	<button v-if="signed" type="button" v-on:click="clear()" class="mt-3 btn btn-md btn-secondary">Limpiar firma</button>
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-info" v-on:click="save(true);" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Vista previa</button>
					<div class="ml-auto">
					    <button type="button" class="btn btn-success mr-2" v-on:click="save(false);" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
					    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('sign.add',this.openModal);
        },
        data(){
            return {
            	base: window.urls['contracts-signed.index'],
                signed: false,
                solicitant: null,
                contract:null,
                saving: false,
                signdata:null,
                resized:false,
                is_solicitant: "1",
                solicitant: null,
                titles: {
                    ecologic_arc: 'Certificación de Arca Ecológica',
                    authorization_pickup_ash: 'Autorización Recogida de Cenizas',
                    healt_authorization: 'Autorización Gestión Sanidad',
                    authorization_incineration: 'Autorización incineración',
                    authorization_service: 'Autorización de servicio',
                    exit_hospital_residence: 'Hoja salida Hospital / Residencia',
                    authorization_pickup_ash: 'Autorización recogida cenizas',
                    civil_register: 'Registro Civil',
                    receipt_payment_priest: 'Recibo Cura Particular',
                    receipt_payment_insurance: 'Recibo de Seguro',
                    questionary_declaration_death: "Cuestionario para la declaración de la defunción",
                },
                sign:{
                    type_of_document: null,
                	document: null,
                    name: null,
                    address: null,
                    name_civil_register: null, //incluyo este valor
                    city: null,
                    state: null,
                    phone: null,
                    relation: null,

                },
                errors: {
                    id_role: false,
                    name: false,
                    lastname: false,
                    email: false
                },
                fieldsToShowByDocument: {
                    ecologic_arc: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    authorization_pickup_ash: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    healt_authorization: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    authorization_incineration: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    authorization_service: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    exit_hospital_residence: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    authorization_pickup_ash: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    civil_register: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    receipt_payment_priest: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    receipt_payment_insurance: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                    },
                    questionary_declaration_death: {
                        is_solicitant: true,
                        name: true,
                        type_of_document: true,
                        document:true,
                        address: true,
                        name_civil_register: true,
                        tome_register: true,
					    page_register: true, 
					    observations_register: true, 
                    },
                },
            }
        },
        methods:{
            getTitle(){
                if(this.sign && this.sign.type)
                    return this.titles[this.sign.type];
            },
            shouldShow(field){
                if(this.fieldsToShowByDocument[this.sign.type] == null || this.fieldsToShowByDocument[this.sign.type] == undefined)
                    return;
                return this.fieldsToShowByDocument[this.sign.type][field] == true;
            },
        	onBegin(){
				this.$refs.signaturePad.resizeCanvas();
        	},
        	onEnd(){
        		this.getSignature();
        	},
        	clear(){
        		this.$refs.signaturePad.clearSignature();
        		this.signdata = null;
    			this.signed = false;
        	},
        	getSignature() {
		      	const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
		      	this.signed = !isEmpty;
		      	this.signdata = null;
		      	if(!isEmpty){
		      		this.signfile = null;
                    this.sign.new_sign = data;
		      	}
		  	},
		  	fillDetails(){
		  		if(this.sign.is_solicitant == "1"){
                    this.sign.type_of_document = this.solicitant.document_type;
                    this.sign.document = this.solicitant.dni;
                    this.sign.name = this.solicitant.name + ' ' + this.solicitant.surname + ' ' + this.solicitant.lastname;
                    this.sign.address = this.solicitant.address;
                    this.sign.city = this.solicitant.city;
                    this.sign.state = this.solicitant.state;
                    this.sign.phone = this.solicitant.phone;
                    this.sign.name_civil_register = this.contract.name_civil_register; //incluyo este valor
                    this.sign.tome_register = this.contract.tome_register; //incluyo este valor
                    this.sign.page_register = this.contract.page_register; //incluyo este valor
                    this.sign.observations_register = this.contract.observations_register; //incluyo este valor
                    this.sign.relation = this.solicitant.relation;
		  		}
		  	},
        	openModal(data){
                if(!data)
                    return ;
                var solicitant = data.solicitant;
                var contract = data.contract;
                var type = data.type;

                if(solicitant)
            		this.solicitant = solicitant;
                
                if(contract)
            		this.contract = contract;

                this.sign = {
                    id_contract: contract.id,
                    type: type,
                    is_solicitant: "1",
                    type_of_document: "dni",
					document: null,
					name: null,
					address: null,
					name_civil_register: null, //incluyo este valor
					tome_register: null, //incluyo este valor
					page_register: null, //incluyo este valor
					observations_register: null, //incluyo este valor
					city: null,
					state: null,
					phone: null,
					relation: null,
                    signed: false,
                    new_sign: false
                };
                this.clear();
                
                this.fillDetails();
                this.resetErrors();
        		$('#signModal').modal({'backdrop':'static'});
        		var vm = this;
        	},
            resetErrors(){
                this.errors = {
                    document: false,
					name: false,
					address: false,
					name_civil_register: false, //incluyo este valor
					tome_register: false, //incluyo este valor
					page_register: false, //incluyo este valor
					observations_register: false, //incluyo este valor
					city: false,
					state: false,
					phone: false,
					relation: false,
                };
            },
            firstLetterOnUpperCase(field){
                var aux = this.user[field].split(" ");
                var final = "";
                for(var i = 0; i < aux.length; i++){
                    final += aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i < aux.length - 1) final += " ";
                }
                this.user[field] = final;
            },
            validate(){
                var valid = true;
                this.errors.id_role = this.user.id_role == null || this.user.id_role == "";
                this.errors.name = this.user.name == null || this.user.name == "";
                this.errors.lastname = this.user.lastname == null || this.user.lastname == "";
                this.errors.email = this.user.email == null || this.user.email == "";
                this.errors.name_civil_register = this.user.name_civil_register == null || this.user.name_civil_register == ""; //incluyo este valor
                this.errors.tome_register = this.user.tome_register == null || this.user.tome_register == ""; //incluyo este valor
                this.errors.page_register = this.user.page_register == null || this.user.page_register == ""; //incluyo este valor
                this.errors.observations_register = this.user.observations_register == null || this.user.observations_register == ""; //incluyo este valor
                this.errors.password = this.user.id == null && (this.user.password == null || this.user.password == "");
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },

            save(preview){
                //if(!this.validate())
                //    return;
                var url=this.base;
                var vm=this;

                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.sign,
                    responseType: 'json',
                }).then(function(response){
                    var message = preview?'Generando vista previa':'El documento se ha firmado'
                    vm.saving=false;
                    vm.$root.$emit('signed-documents.updated', response.data);
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      message,
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#signModal').modal('hide');
            }
        }
    }
</script>
