<template>
	<div class="modal fade action-sheet opax" id="contractReminderModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ reminder.id?'Editar recordatorio':'Nuevo recordatorio'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12 col-md-4">
                            <label>Empresa</label>
                            <select type="text" class="form-control" v-model="reminder.id_company">
                                <option v-for="c in companies" v-bind:value="c.id">{{ c.name  }}</option>
                            </select>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Lugar de fallecimiento</label>
                            <input type="text" class="form-control" v-model="reminder.deceasing_place"  />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Fecha del fallecimiento</label>
                            <datepicker v-model="reminder.deceased_date" style="display: block;width: 100%"  v-bind:lang="lang" format="DD/MM/YYYY" value-type="YYYY-MM-DD" type="date"></datepicker>
                        </div>
                        <div class="col-12 col-md-8">
                            <label>Nombre del fallecido</label>
                            <input type="text" class="form-control" v-model="reminder.deceased_name" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Edad del fallecido</label>
                            <input type="number" min="0" max="200" step="1" class="form-control" v-model="reminder.age" />
                        </div>
                        <div class="col-12">
                            <label>Texto familiar</label>
                            <input type="text" class="form-control" v-model="reminder.text" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Lugar de inhumación</label>
                            <input type="text" class="form-control" v-model="reminder.inhumation_place" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Fecha de inhumación</label>
                            <datepicker v-model="reminder.inhumation_date" style="display: block;width: 100%"  v-bind:lang="lang" format="DD/MM/YYYY" value-type="YYYY-MM-DD" type="date"></datepicker>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Hora de inhumación</label>
                            <datepicker v-model="reminder.inhumation_time" style="display: block;width: 100%"  v-bind:lang="lang" format="HH:mm" value-type="HH:mm" type="time"></datepicker>
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Modelo</label>
                            <input type="text" class="form-control" v-model="reminder.model" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Oración</label>
                            <input type="text" class="form-control" v-model="reminder.pray" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Cantidad</label>
                            <input type="number" min="0" max="200" step="1" class="form-control" v-model="reminder.amount" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Entregar en</label>
                            <input type="text" class="form-control" v-model="reminder.delivery_on" />
                        </div>
                        <div class="col-12 col-md-4">
                            <label>Sala</label>
                            <input type="text" class="form-control" v-model="reminder.delivery_room" />
                        </div>
                        <!--
                        <div class="col-12 col-md-4">
                            <label>Precio de Sala</label>
                            <input type="number" class="form-control" v-model="reminder.delivery_price" />
                        </div>
                        -->
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    import Datepicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';
    import 'vue2-datepicker/locale/es';
    export default {
        components: { Datepicker },
        mounted() {
            this.$root.$on('contracts.context',this.loadCompanies);
            this.$root.$on('contract-reminders.add', this.openModal);
            this.$root.$on('contract-reminders.edit', this.openModal);
        },
        data(){
            return {
            	base: window.urls['contract.reminders.index'],
                saving: false,
                lang: {
					formatLocale: {
	          			firstDayOfWeek: 1,
	              	},
	              	monthBeforeYear: false,
	          	},
                companies: [],
                reminder:{
                    id_contract: null,
                    id_company: null,
                    deceased_name: null,
                    deceasing_place: null,
                    deceased_date: null,
                    age: null,
                    text: null,
                    inhumation_place: null,
                    inhumation_date: null,
                    inhumation_time: null,
                    model: null,
                    pray: null,
                    amount: 1,
                    delivery_on: null,
                    delivery_room: null,
                    //delivery_price:null,
                },
                errors: {
					id_company: false,
					deceased_name: false,
					deceasing_place: false,
					deceased_date: false,
					age: false,
					text: false,
					inhumation_place: false,
					inhumation_date: false,
                    inhumation_time: false,
					model: false,
					pray: false,
					amount: false,
					delivery_on: false,
					delivery_room: false,
                    //delivery_price: false,
                },
            }
        },
        methods:{
            loadCompanies(data){
                this.companies = data.companies;
            },
        	openModal(data){
        		var contract = data.contract;
        		var reminder = data.reminder;
                var deceased = data.deceased;
                if(reminder){
                    this.reminder = {
                    	id: reminder.id,
                        id_contract: reminder.id_contract,
	                    id_company: reminder.id_company,
	                    deceased_name: reminder.deceased_name,
	                    deceasing_place: reminder.deceasing_place,
	                    deceased_date: reminder.deceased_date,
	                    age: reminder.age,
	                    text: reminder.text,
	                    inhumation_place: reminder.inhumation_place,
	                    inhumation_date: reminder.inhumation_date,
                        inhumation_time: reminder.inhumation_time,
	                    model: reminder.model,
	                    pray: reminder.pray,
	                    amount: reminder.amount,
	                    delivery_on: reminder.delivery_on,
	                    delivery_room: reminder.delivery_room,
                        //delivery_price: reminder.delivery_price,
                    };
                }else{
                    this.reminder = {
						id_contract: contract.id,
						id_company: null,
						deceased_name: deceased?.name,
						deceasing_place: contract.deceasing_place,
						deceased_date: contract.deceasing_date,
						age: null,
						text: null,
						inhumation_place: contract.inhumation_cemetery_property,
						inhumation_date: contract.inhumation_date,
                        inhumation_time: null,
						model: null,
						pray: null,
						amount: null,
						delivery_on: null,
						delivery_room: null,
                        //delivery_price: null,
                    };
                }
                this.resetErrors();
        		$('#contractReminderModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors = {
                    id_company: false,
					deceased_name: false,
					deceasing_place: false,
					deceased_date: false,
					age: false,
					text: false,
					inhumation_place: false,
					inhumation_date: false,
                    inhumation_time: false,
					model: false,
					pray: false,
					amount: false,
					delivery_on: false,
					delivery_room: false,
                    //delivery_price: false,
                };
            },
            validate(){
            	var valid = true;
				this.errors.id_company = this.reminder.id_company == null || this.reminder.id_company == undefined || this.reminder.id_company == "";
				this.errors.deceased_name = this.reminder.deceased_name == null || this.reminder.deceased_name == undefined || this.reminder.deceased_name == "";
				this.errors.deceasing_place = this.reminder.deceasing_place == null || this.reminder.deceasing_place == undefined || this.reminder.deceasing_place == "";
				this.errors.deceased_date = this.reminder.deceased_date == null || this.reminder.deceased_date == undefined || this.reminder.deceased_date == "";
				this.errors.age = this.reminder.age == null || this.reminder.age == undefined || this.reminder.age == "";
				this.errors.text = this.reminder.text == null || this.reminder.text == undefined || this.reminder.text == "";
				this.errors.inhumation_place = this.reminder.inhumation_place == null || this.reminder.inhumation_place == undefined || this.reminder.inhumation_place == "";
				this.errors.inhumation_date = this.reminder.inhumation_date == null || this.reminder.inhumation_date == undefined || this.reminder.inhumation_date == "";
                this.errors.inhumation_time = this.reminder.inhumation_time == null || this.reminder.inhumation_time == undefined || this.reminder.inhumation_time == "";
				this.errors.model = this.reminder.model == null || this.reminder.model == undefined || this.reminder.model == "";
				this.errors.pray = this.reminder.pray == null || this.reminder.pray == undefined || this.reminder.pray == "";
				this.errors.amount = this.reminder.amount == null || this.reminder.amount == undefined || this.reminder.amount == "";
				this.errors.delivery_on = this.reminder.delivery_on == null || this.reminder.delivery_on == undefined || this.reminder.delivery_on == "";
				this.errors.delivery_room = this.reminder.delivery_room == null || this.reminder.delivery_room == undefined || this.reminder.delivery_room == "";
				//this.errors.delivery_price = this.reminder.delivery_price == null || this.reminder.delivery_price == undefined || this.reminder.delivery_price == "";
                
                for(var i in this.errors)
                    valid &= !this.errors[i];
                
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url = this.base;
                var vm = this;
                if(this.reminder.id)
                	url += '/' + this.reminder.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.reminder,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contract-reminders.updated', response.data);
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el recordatorio',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#contractReminderModal').modal('hide');
            }
        }
    }
</script>
