<template>
	<div class="card">
		<div class="card-header">
	        Resumen {{ filter.from|dayFormat }} a {{ filter.to|dayFormat }}
	    </div>
	    <div class="card-body">
    		<b>Total trabajado:</b> 
    		<span v-show="!loading">{{ resume.hours }} horas y {{ resume.minutes }} minutos</span>
    		<span v-show="loading">Cargando</span>
		</div>
	</div>
</template>
<script>
	export default{
		mounted(){
			this.$root.$on('inout.search',this.updateFilter);
            this.$root.$on('inout.updated',this.updateFilter);
			this.getResume();
		},
		filters:{
            dayFormat(input){
                return moment(input).format('DD/MM/YYYY')
            },
        },
		data(){
			var startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
			var endOfMonth   = moment().endOf('month').format('YYYY-MM-DD');
			return {
				base:window.urls['inout.index'],
				resume: {
					hours: 0,
					minutes: 0
				},
				loading: true,
				initialFrom: startOfMonth,
				initialTo: endOfMonth,
				filter:{
	                from: startOfMonth,
	                to: endOfMonth,
	                user_id: null
	            },
			}
		},
		methods: {
			updateFilter(newFilter){
				if(!newFilter.from || !newFilter.to){
					this.filter.from = this.initialFrom;
					this.filter.to = this.initialTo;
				}else{
					this.filter.from = newFilter.from;
					this.filter.to = newFilter.to;
				}
				if(!newFilter.user_id)
					this.filter.user_id = null;
				else
					this.filter.user_id = newFilter.user_id;
				this.getResume();
			},
			getResume(){
				var vm = this;
				this.loading = true;
				axios({
	                method: 'get',
	                url: vm.base + '/resume?query=' + JSON.stringify(this.filter),
	                responseType: 'json',
	            }).then(function(response){
	            	var hours = response.data.hours != null?response.data.hours:'0';
	            	var minutes = response.data.minutes != null?response.data.minutes:'0';
	            	vm.loading = false;
	            	vm.processResume(hours,minutes);

	            })
			},
			processResume(hours, minutes){
				var extraHours = parseInt(minutes / 60);
				var finalMinutes = parseInt(minutes % 60);
            	this.resume = {
            		hours: parseInt(hours) + parseInt(extraHours),
					minutes: finalMinutes
            	};
			}
		}
	}
</script>