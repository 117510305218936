<template>
    <div class="row mb-3">
    	<div class="col-12">
    		<p>Mostrando: {{ results.count }} de {{ results.total }}</p>
    	</div>
        <div class="col-12 col-md">
            <div class="row" v-if="searching">
                <div class="form-user col-12 col-md-3">
                    <label>Nombre</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Nombre" v-model="filter.name" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Correo electrónico</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Correo electrónico" v-model="filter.email" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Teléfono</label>
                    <input spellcheck="true" type="text" class="form-control" placeholder="Teléfono" v-model="filter.phone" v-on:change="search"/>
                </div>
                <div class="form-user col-12 col-md-3">
                    <label>Tipo de cliente</label>
                    <select class="form-control" v-model="filter.type" v-on:change="search">
                        <option value="">Todos</option>
                        <option value="residence">Residencia</option>
                        <option value="hospital">Clínica / Hospital</option>
                        <option value="other">Otro</option>
                    </select>
                </div>
                <div class="col-12 mt-3 text-right">
                    <button class="my-auto btn btn-primary" v-on:click="search()" v-bind:disabled="loading">
                        <i v-show="loading" class="fa fa-spin fa-spinner"></i>
                        {{ loading?'Buscando':'Buscar' }}
                    </button>
                    <button class="my-auto btn btn-warning" v-on:click="clean()">Limpiar</button>
                    <button class="my-auto btn btn-danger" v-on:click="toggleSearch()">Cancelar</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
    	props:['base'],
        mounted() {
            this.$root.$on('clients.loaded',this.tableLoaded);
            this.$root.$on('clients.filter',this.toggleSearch);
        },
        data(){
        	return {
                searching: true,
                loading: true,
                filter: {
                    type: ""
                },
                results: {
                    total: 0,
                    count: 0
                },
        	}
	  	},
        methods:{
            tableLoaded(data){
        		this.results = data;
        		this.loading = false;
        	},
            downloadExcel(){
                var json = JSON.stringify(this.query)
                window.open(this.base + '/download?query=' + json, '_blank');
            },
            toggleSearch(){
            	this.searching = !this.searching;
            },
            search(){
                this.loading = true;
                this.$root.$emit('clients.search', this.filter);
            },
            clean(){
            	for(var i in this.filter)
            		this.filter[i] = "";
            	this.search();
            }
        }
    }
</script>
