<template>
	<div class="modal fade action-sheet opax" id="bankaccountModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ bankaccount.id?'Editar número de cuenta':'Nuevo número de cuenta'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Banco *</label>
                            <input type="text" class="form-control" v-model="bankaccount.bank_name" v-bind:class="{'is-invalid': errors.bank_name}" placeholder="Banco">
                        </div>
                        <div class="col-12">
                            <label>IBAN *</label>
                            <input type="text" class="form-control" v-model="bankaccount.iban" v-bind:class="{'is-invalid': errors.iban}" placeholder="IBAN">
                        </div>
                        <div class="col-12">
                            <label>BIC / SWIFT</label>
                            <input type="text" class="form-control" v-model="bankaccount.bic_swift" v-bind:class="{'is-invalid': errors.bic_swift}" placeholder="BIC / SWIFT">
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('bankaccounts.add',this.openModal);
            this.$root.$on('bankaccounts.edit',this.openModal);
        },
        data(){
            return {
            	base:window.urls['bankaccounts.index'],
                saving: false,
                bankaccount:{
                    bank_name: null,
                    iban: null,
                    bic_swift: null
                },
                errors: {
                    bank_name: false,
                    iban: false,
                    bic_swift: false
                },
            }
        },
        methods:{
        	openModal(data){
                if(data){
                    this.bankaccount={
                        id: data.id,
                        bank_name: data.bank_name,
                        iban: data.iban,
                        bic_swift: data.bic_swift
                    };
                }else{
                    this.bankaccount={
                        bank_name: null,
                        iban: null,
                        bic_swift: null
                    };
                }
                this.resetErrors();
        		$('#bankaccountModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    bank_name: false,
                    iban: false,
                    bic_swift: false
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.bankaccount[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.bankaccount[field]=final;
            },
            validate(){
                var valid = true;
                this.errors.bank_name = this.bankaccount.bank_name == null || this.bankaccount.bank_name == "";
                this.errors.iban = this.bankaccount.iban == null || this.bankaccount.iban == "";
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.bankaccount.id) url += '/' + this.bankaccount.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.bankaccount,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('bankaccounts.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el número de cuenta',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#bankaccountModal').modal('hide');
            }
        }
    }
</script>
