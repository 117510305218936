<template>
	<div class="modal fade action-sheet opax" id="budgetContractModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ budget.id?'Editar contrato':'Nuevo contrato'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 col-md-4">
							<label>Cliente *</label>
							<select class="form-control" v-model="budget.id_client" v-on:change="selectedClient()">
								<option v-for="c in clients" v-bind:value="c.id">{{ c.name }} {{ c.surname }}</option>
							</select>
						</div>
						<div class="col-12 col-md-4">
							<label>Forma de pago</label>
							<select class="form-control" v-model="budget.payment_method">
								<option value="transfer">Transferencia bancaria</option>
								<option value="bank">Recibo bancario</option>
								<option value="cash">Efectivo</option>
							</select>
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-3">
							<label>Titular</label>
							<select class="form-control" v-model="budget.bank_owner" v-on:change="changedBankOwner()">
								<option value="solicitant">Solicitante</option>
								<option value="deceased">Fallecido</option>
								<option value="company">Compañía de seguros</option>
								<option value="other">Otro</option>
							</select>
						</div>
						<div class="col-12 col-md-3">
							<label>Nombre</label>
							<input class="form-control" type="text" v-model="budget.owner_fullname" v-bind:disabled="budget.bank_owner != 'other'" />
						</div>
						<div class="col-12 col-md-2">
							<label>DNI</label>
							<input class="form-control" type="text" v-model="budget.owner_dni" v-bind:disabled="budget.bank_owner != 'other'" />
						</div>
						<div class="col-12 col-md-4">
							<label>Nº de cuenta</label>
							<input class="form-control" type="text" v-model="budget.bank_account" v-bind:disabled="budget.payment_method != 'bank'" />
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-3">
							<label>Autorizado</label>
							<select class="form-control" v-model="budget.bank_authorized" v-bind:disabled="budget.payment_method == 'cash'">
								<option value="client">Cliente</option>
								<option value="other">Otro</option>
							</select>
						</div>
						<div class="col-12 col-md-3">
							<label>Nombre</label>
							<input class="form-control" type="text" v-model="budget.authorized_fullname" v-bind:disabled="budget.bank_authorized != 'other'" />
						</div>
						<div class="col-12 col-md-2">
							<label>DNI</label>
							<input class="form-control" type="text" v-model="budget.authorized_dni" v-bind:disabled="budget.bank_authorized != 'other'" />
						</div>
						<div class="col-12 col-md-4">
							<label>Observaciones de pago</label>
							<input class="form-control" type="text" v-model="budget.payment_observations" />
						</div>
					</div>
					<div class="row">
						<div class="col-12 col-md-3">
			                <label>Tipo de expediente *</label>
			                <select class="form-control" v-model="budget.type_of_expedient" v-bind:class="{'is-invalid':errors.type_of_expedient}">
			                	<option value="complete">Completo</option>
	                            <option value="donations">Donaciones, miembros y fetos</option>
	                            <option value="increase">Mejora</option>
                                <option value="discount">Tipo descuento</option>
                                <option value="reception">Recepción</option>
                                <option value="enferentry">Enferetramiento</option>
                                <option value="processing">Tramitación</option>
			                </select>
			            </div>
			            <div class="col-12 col-md-3">
			                <label>Método contratación *</label>
			                <select class="form-control" v-model="budget.contratation_type" v-bind:class="{'is-invalid':errors.type_of_client}">
			                    <option value="online">Online</option>
			                    <option value="present">Presencial</option>
			                </select>
			            </div>
			            <div class="col-12 col-md" v-if="is_company">
			            	<label>Nº de Póliza</label>
			            	<input type="text" class="form-control" v-model="budget.insurance_number" />
			            </div>
			            <div class="col-12 col-md" v-if="is_company">
			            	<label>Nº de Siniestro</label>
			            	<input type="text" class="form-control" v-model="budget.sinister_number" />
			            </div>
			            <div class="col-12 col-md" v-if="is_company">
			            	<label>Observaciones</label>
			            	<input type="text" class="form-control" v-model="budget.observations" />
			            </div>
					</div>
					<hr class="my-2">
					<div>
						<div class="row">
							<div class="col-12 col-md-4">
								<label>Plantilla de contrato</label>
								<v-select placeholder="Elegir plantilla" v-on:search="searchTemplate" v-bind:options="matchedTemplates" v-model="template" v-on:input="selectTemplate" :filter="customSearch">
			                        <template slot="spinner">
			                            <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
			                        </template>
			                    </v-select>
							</div>
							<div class="col-12 col-md-4" v-if="!isAdmin">
								<div class="h-100 d-flex">
									<button class="mt-auto btn btn-info" v-bind:disabled="rows.length == 0" v-on:click="convertToTemplate()">Crear plantilla</button>
								</div>
							</div>
						</div>
						<div class="row my-3">
							<div class="col-12 col-md-4">
								<!--<button class="btn" v-bind:class="{'btn-info':productType != 'crowns','btn-success':productType != 'crowns'}" v-on:click="setProductType('crowns')">Coronas</button>
								<button class="btn" v-bind:class="{'btn-info':productType != 'centers','btn-success':productType != 'centers'}" v-on:click="setProductType('centers')">Centros</button>
								<button class="btn" v-bind:class="{'btn-info':productType != 'boquets','btn-success':productType != 'boquets'}" v-on:click="setProductType('boquets')">Ramos</button>
								<button class="btn" v-bind:class="{'btn-info':productType != 'supplied','btn-success':productType != 'supplied'}" v-on:click="setProductType('supplied')">Suplidos</button>-->
								<button class="btn" v-bind:class="{'btn-info':productType != 'product','btn-success':productType == 'product'}" v-on:click="setProductType('product')">Productos</button>
								<button class="btn" v-bind:class="{'btn-info':productType != 'supplied','btn-success':productType == 'supplied'}" v-on:click="setProductType('supplied')">Suplidos</button>
							</div>
							<div class="col-12 col-md">
								<v-select placeholder="Añadir producto" v-on:search="searchProduct" v-bind:options="matchedProducts" v-model="product" v-on:input="selectProduct" :filter="customSearch">
			                        <template slot="spinner">
			                            <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
			                        </template>
									<template #no-options="{ search, searching, loading }">
										Ninguna coincidencia
									</template>
			                    </v-select>
							</div>
						</div>
					</div>
					<hr class="my-2">
					<div class="row mb-2">
						<div class="col-12 col-md-4">
							<label>Descuento general</label>
						</div>
						<div class="col-12 col-md">
							<input type="number" class="form-control form-control-sm" v-model="generalDiscount" placeholder="Descuento general">
						</div>
						<div class="col-12 col-md-3">
							<button class="btn btn-info btn-sm w-100" v-on:click="applyGeneralDiscount()">Aplicar</button>
						</div>
					</div>
					<div class="mt-5">
						<div class="table-responsive">
							<table class="table">
								<thead>
									<tr>
										<th colspan="6" class="text-right">Totales</th>
										<td>{{ getTotalBase()|round }}</td>
										<td>{{ getTotalDiscount()|round }}</td>
										<td>{{ getTotalIva()|round }}</td>
										<td>{{ getTotalPrice()|round }}</td>
									</tr>
									<tr>
										<th></th>
										<th>Descripción</th>
										<th>Precio unitario</th>
										<th>Base imponible</th>
										<th>Cantidad</th>
										<th>% Dto</th>
										<th>% IVA</th>
										<th>Dto</th>
										<th>IVA</th>
										<th>Total</th>
									</tr>
								</thead>
								<tbody>
									<tr v-for="(r, index) in rows">
										<td>
											<a href="javascript:void(0)" v-on:click="deleteRow(index)" class="text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Eliminar"><i class="fas fa-trash"></i></a>
										</td>
										<td>
											<input v-if="r.id_portfolio == 0" type="text" v-model="r.concept" class="form-control form-control-sm"/>
											<span v-else>{{ r.concept }}</span>
										</td>
										<td>
											<input v-if="!budget.id_contract_template" type="number" min="0" step="0.01" v-model="r.price" class="form-control form-control-sm"/>
											<span v-else>{{ r.price }}</span>
										</td>
										<td>
											{{ ((r.price * r.qty) - getDiscount(r))|round }}
										</td>
										<td width="130">
											{{ r.qty }}
										</td>
										<td width="130">
											<input v-if="r.id_portfolio == 0" type="number" min="0" step="0.01" v-model="r.p_discount" class="form-control form-control-sm"/>
											<span v-else>{{ r.p_discount }}</span>
										</td>
										<td>
											<select v-if="r.id_portfolio == 0" class="form-control form-control-sm" v-model="r.p_tax">
												<option value="21">21%</option>
												<option value="10">10%</option>
												<option value="4">4%</option>
												<option value="0">Extento</option>
											</select>
											<span v-else>{{ r.p_tax }}</span>
										</td>
										<td>
											{{ getDiscount(r)|round }}
										</td>
										<td>
											{{ getIva(r)|round }}
										</td>
										<td width="130">
											<b>{{ getTotal(r)|round }}</b>
										</td>
									</tr>
								</tbody>
								<tfoot>
									<tr>
										<th colspan="6" class="text-right">Totales</th>
										<td>{{ getTotalBase()|round }}</td>
										<td>{{ getTotalDiscount()|round }}</td>
										<td>{{ getTotalIva()|round }}</td>
										<td>{{ getTotalPrice()|round }}</td>
									</tr>
								</tfoot>
							</table>
						</div>
					</div>
				</div>
				<div class="modal-footer">
					<a v-show="budget.id && budget.id_client" class="btn btn-info" v-bind:href="base + '/see/' + budget.id" target="_blank">Vista previa</a>
					<a v-show="budget.id && budget.sign" class="btn btn-info" v-bind:href="base + '/invoice/' + budget.id" target="_blank">Factura</a>
					<!-- Ver precontrato + firmarlo -->
					<div class="ml-auto">
					    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving || budget.sign"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
					    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
        	this.$root.$on('contracts.edit',this.setIdContract);
            this.$root.$on('contracts.contracts.add',this.openModal);
            this.$root.$on('contracts.contracts.edit',this.openModal);
            this.$root.$on('contract-templates.updated', this.getTemplates);
            this.getPortfolio();
            this.getTemplates();
            this.configureBroadcastMessaging();
            
        },
        filters: {
        	round(input){
        		if(input)
        			return parseFloat(input).toFixed(2).replace(".", ",");
        		return "0,00";
        	}
        },
        data(){
            return {
            	base: window.urls['contracts.clients.budget.index'],
            	basePortfolio: window.urls['portfolio.index'],
            	baseTemplate:window.urls['contract-templates.index'],
            	isAdmin: user_role == 'admin',
            	spinner: false,
            	clients: [],
				chosenClient: null,
                rows: [],
                product: null,
                products: [],
                matchedProducts: [],
                templates:[],                
				matchedTemplates: [],
				template: null,
                productType: null,
                id_contract: null,
                saving: false,
                generalDiscount: null,
                is_company:false,
                budget: {
                	id: null,
                    id_contract: null,
					id_contract_template: null,
					payment_method: null,
					bank_owner: null,
					bank_authorized: null,
					type_of_expedient: null,
					contratation_type: null,
					owner_fullname: null,
					owner_dni: null,
					bank_account: null,
					authorized_fullname: null,
					authorized_dni: null,
					payment_observations: null,
					price: null,
					tax: null,
					discount: null,
					total: null,
					sign: null
                },
                errors: {
					id_contract: false,
					id_budget: false,
					id_contract_template: false,
					payment_method: false,
					bank_owner: false,
					bank_authorized: false,
					type_of_expedient: false,
					contratation_type: false,
					owner_fullname: false,
					owner_dni: false,
					bank_account: false,
					authorized_fullname: false,
					authorized_dni: false,
					payment_observations: false,
					price: false,
					tax: false,
					discount: false,
					total: false,
                },
            }
        },
        methods:{
        	configureBroadcastMessaging(){
    			var bc = new BroadcastChannel('sign.budget.channel');
            	bc.onmessage = this.contractSigned;
        	},
        	contractSigned(ev){
        		var data = ev.data;
        		var id = parseInt(data.id);
        		if(this.budget.id == id){
        			this.budget.sign = data.sign;
        			var copy = JSON.parse(JSON.stringify(this.budget));
        			this.$root.$emit('contracts.budgets.updated', copy);
        		}
        	},
        	selectedClient(){
        		this.is_company = false;
        		for(var i in this.clients){
					if(this.clients[i].id == this.budget.id_client)
						this.chosenClient = this.clients[i];
        			if(this.clients[i].id == this.budget.id_client && this.clients[i].type_of_client != 'particular'){
        				this.is_company = true;
        				return ;
        			}
        		}
        	},
			changedBankOwner(){
				if(!this.chosenClient)
					return
				if(this.budget.bank_owner == 'other'){
					this.budget.owner_fullname = this.chosenClient.name;
					this.budget.owner_dni = this.chosenClient.dni;
				}else{
					this.budget.owner_fullname = null;
					this.budget.owner_dni = null;
				}
			},
			changedBankAuthorized(){
				if(!this.chosenClient)
					return
				if(this.budget.bank_authorized == 'other'){
					this.budget.authorized_fullname = this.chosenClient.name;
					this.budget.authorized_dni = this.chosenClient.dni;
				}else{
					this.budget.authorized_fullname = null;
					this.budget.authorized_dni = null;
				}
			},
        	setIdContract(data){
        		this.id_contract = data.id;
        	},
        	getDiscount(row){
        		return row.qty * row.price * row.p_discount / 100;
        	},
        	getIva(row){
        		var discount = this.getDiscount(row);
        		return (row.qty * row.price - discount) * row.p_tax / 100
        	},
        	getTotal(row){
        		var iva = this.getIva(row);
        		var discount = this.getDiscount(row);
        		var price = row.qty * parseFloat(row.price);
        		return price + iva - discount;
        	},
        	getTotalBase(){
				var total = 0;
				for(var i in this.rows){
					total += parseFloat(this.rows[i].price * this.rows[i].qty);
				}
				return total;
			},
			getTotalDiscount(){
				var total = 0;
				for(var i in this.rows){
					total += this.getDiscount(this.rows[i]) * this.rows[i].qty;
				}
				return total;
			},
			getTotalIva(){
				var total = 0;
				for(var i in this.rows){
					total += this.getIva(this.rows[i]) * this.rows[i].qty;
				}
				return total;
			},
			getTotalPrice(){
				var total = 0;
				for(var i in this.rows){
					total += this.getTotal(this.rows[i]);
				}
				return total;
			},
        	applyGeneralDiscount(){
        		for(var i in this.rows){
        			this.rows[i].p_discount = this.generalDiscount;
        		}
        	},
        	getPortfolio(){
        		var vm = this;
        		var url = this.basePortfolio + '/table?limit=100000&page=1&orderBy=name&ascending=1&query={active:true}';
        		axios({
                    method: 'get',
                    url: url,
                    responseType: 'json',
                }).then(function(response){
                	var products = response.data.data;
                	for(var i in products){
                		var info = {
                			code: products[i].id,
							canEdit: false,
                			label: products[i].name,
                			type: products[i].type,
                			info: products[i]
                		};
                		vm.products.push(info);
                		vm.matchedProducts.push(info);
                	}
                	vm.addOthers();
                })
                .catch(function (error) {
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
        	},
        	addOthers(){
        		var info = {
        			code: 0,
					canEdit: true,
        			label: "OTROS",
        			type: "other",
        			info: null
        		};
        		this.products.push(info);
        		this.matchedProducts.push(info);
        	},
        	getTemplates(){
        		var vm = this;
        		var url = this.baseTemplate + '/all';
        		axios({
                    method: 'get',
                    url: url,
                    responseType: 'json',
                }).then(function(response){
                	var templates = response.data;
                	vm.templates = [];
                	for(var i in templates){
                		var info = {
                			code: templates[i].id,
                			label: templates[i].name,
                			info: templates[i]
                		};
                		vm.templates.push(info);
                		vm.matchedTemplates.push(info);
                	}
                })
                .catch(function (error) {
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
        	},
        	convertToTemplate(){
        		var rows = JSON.parse(JSON.stringify(this.rows));
        		this.$root.$emit('contract-templates.create-template', rows);
        		
        	},
        	searchTemplate(input){
        		this.matchedTemplates = [];
				for(var i in this.templates){
                    var template = this.templates[i].label.toLocaleLowerCase();
                    if(template.indexOf(input) != -1)
                        this.matchedTemplates.push(this.templates[i]);
                }
                this.spinner = false;
        	},
			selectTemplate(value){
				if(!value)
					return ;
				this.budget.id_contract_template = value.code;
				var rows = value.info.rows;
				for(var i in rows){
					this.rows.push({
	        			id_portfolio: rows[i].product_id,
	  					concept: rows[i].product.name,
	  					price: rows[i].price,
	  					p_tax: rows[i].p_tax,
	  					qty: rows[i].qty,
	  					p_discount: rows[i].p_discount,
	  					total: rows[i].total
	        		});
				}
			},

        	searchProduct(input){
				this.matchedProducts = [];
				for(var i in this.products){
                    var product = this.products[i].label.toLocaleLowerCase();
                    if(this.products[i].type == this.productType || this.products[i].type == 'other')
	                    if(product.indexOf(input.toLocaleLowerCase()) != -1)
	                        this.matchedProducts.push(this.products[i]);
                }
                this.spinner = false;
        	},
        	selectProduct(value){
        		if(!value)
        			return ;
        		if(!value.info){
        			this.rows.push({
	        			id_portfolio: 0,
	  					concept: "OTROS",
	  					price: 0,
	  					p_tax: this.productType == 'product' ? 21 : 0,
	  					qty: 1,
	  					p_discount: 0,
	  					total: 0
	        		});
	        		this.product = null;
        			return
        		}
        		var product = value.info;
        		this.rows.push({
        			id_portfolio: product.id,
  					concept: product.name,
  					price: product.price,
  					p_tax: product.taxes,
  					qty: 1,
  					p_discount: 0,
  					total: product.total
        		});
        		this.product = null;
        	},
        	setProductType(newProductType){
        		this.productType = newProductType;
        		this.searchProduct("");
        	},
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            customSearch(options, search) {
                return options;
            },
        	openModal(data){
        		this.budgets = data.budgets;
        		this.clients = data.clients;
				this.productType = 'product';
        		var contract = data.contract;
                if(contract){
                    this.budget = {
                    	id: contract.id,
                    	id_contract: contract.id_contract,
                    	id_client: contract.id_client,
						id_contract_template: contract.id_contract_template,
						payment_method: contract.payment_method,
						bank_owner: contract.bank_owner,
						bank_authorized: contract.bank_authorized,
						type_of_expedient: contract.type_of_expedient,
						contratation_type: contract.contratation_type,
						owner_fullname: contract.owner_fullname,
						owner_dni: contract.owner_dni,
						bank_account: contract.bank_account,
						authorized_fullname: contract.authorized_fullname,
						authorized_dni: contract.authorized_dni,
						payment_observations: contract.payment_observations,
						price: contract.price,
						tax: contract.tax,
						discount: contract.discount,
						total: contract.total,
						sign: contract.sign,
						insurance_number: data.insurance_number,
						sinister_number: data.sinister_number,
						observations: data.observations,
                    };
                    this.rows = contract.rows;
                    this.selectedClient();
                }else{
                    this.budget = {
                    	id_contract: this.id_contract,
                    	id_client: null,
						id_contract_template: null,
						payment_method: null,
						bank_owner: null,
						bank_authorized: null,
						type_of_expedient: null,
						contratation_type: null,
						owner_fullname: null,
						owner_dni: null,
						bank_account: null,
						authorized_fullname: null,
						authorized_dni: null,
						payment_observations: null,
						price: 0,
						tax: 0,
						discount: 0,
						total: 0,
						sign: null,
						insurance_number: null,
						sinister_number: null,
						observations: null

                    };
                    this.rows = [];
                    this.is_company = false;
                }
                this.resetErrors();
        		$('#budgetContractModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors = {
                    id_contract: false,
					id_budget: false,
					id_contract_template: false,
					payment_method: false,
					bank_owner: false,
					bank_authorized: false,
					type_of_expedient: false,
					contratation_type: false,
					owner_fullname: false,
					owner_dni: false,
					bank_account: false,
					authorized_fullname: false,
					authorized_dni: false,
					payment_observations: false,
					price: false,
					tax: false,
					discount: false,
					total: false,
                };
            },
            firstLetterOnUpperCase(field){
                if(this.budget[field] == null)
                    return;
                var aux=this.budget[field].split(" ");
                var final = "";
                for(var i = 0;i < aux.length; i++){
                    final += aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i < aux.length - 1) final += " ";
                }
                this.budget[field] = final;
            },
            validate(){
                var valid = true;
                return valid;
				this.errors.type_of_expedient = this.budget.type_of_expedient == null || this.budget.type_of_expedient == "";
				this.errors.type_of_budget = this.budget.type_of_budget == null || this.budget.type_of_budget == "";
				this.errors.who_is = this.budget.who_is == null;
				this.errors.document_type = this.budget.who_is == 'other' && (this.budget.document_type == null || this.budget.document_type == "");
				this.errors.name = this.budget.who_is == 'other' && (this.budget.name == null || this.budget.name == "");
				this.errors.surname = this.budget.who_is == 'other' && (this.budget.surname == null || this.budget.surname == "");
				this.errors.dni = this.budget.who_is == 'other' && (this.budget.dni == null || this.budget.dni == "");
				this.errors.address = this.budget.who_is == 'other' && (this.budget.address == null || this.budget.address == "");
				this.errors.postcode = this.budget.who_is == 'other' && (this.budget.postcode == null || this.budget.postcode == "");
				this.errors.city = this.budget.who_is == 'other' && (this.budget.city == null || this.budget.city == "");
				this.errors.relation = this.budget.who_is == 'other' && (this.budget.relation == null || this.budget.relation == "");
                for(var i in this.errors)
                    valid &= !this.errors[i];

                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url = this.base;
                var vm = this;
                if(this.budget.id) url += '/' + this.budget.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                    	budget: this.budget,
                    	rows: this.rows,
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('contracts.budgets.updated', response.data);
                    vm.budget.id = response.data.id;
                    vm.rows = response.data.rows;
                    vm.cancel();
                    vm.$swal(
                        'Correcto',
                        'Se ha guardado el contrato',
                        'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    console.log(error);
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            deleteRow(index){
            	this.rows.splice(index, 1);
            },
            cancel(){
            	$('#budgetContractModal').modal('hide');
            }
        }
    }
</script>
