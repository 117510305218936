<template>
	<div class="modal fade action-sheet opax" id="contractTemplateModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ template.id?'Editar plantilla':'Nueva plantilla'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="template.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                    </div>
                    <hr />
                    <legend>Productos</legend>
                    <div class="row">
                    	<div class="col-12 col-md">
							<v-select placeholder="Añadir producto" v-on:search="searchProduct" v-bind:options="matchedProducts" v-model="product" v-on:input="selectProduct" :filter="customSearch">
		                        <template slot="spinner">
		                            <div class="spinner" v-show="spinner"><i class="fa fa-spinner fa-spin"></i></div>
		                        </template>
		                    </v-select>
						</div>
                    </div>
                    <div class="table-responsive mt-3">
						<table class="table">
							<thead>
								<tr>
									<th></th>
									<th>Descripción</th>
                                    <th>Precio unitario</th>
									<th>Base imponible</th>
									<th>Cantidad</th>
									<th>% Dto</th>
									<th>% IVA</th>
									<th>Dto</th>
									<th>IVA</th>
									<th>Total</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(r, index) in rows">
									<td>
										<a href="javascript:void(0)" v-on:click="deleteRow(r, index)" class="text-inverse text-sm" title="" data-toggle="tooltip" data-original-title="Eliminar"><i class="fas fa-trash"></i></a>
									</td>
									<td>
										{{ r.concept }}
									</td>
									<td width="130">
										<input type="number" min="1" v-model="r.price" class="form-control form-control-sm"/>
									</td>
                                    <td>
                                        {{ (r.price - getDiscount(r))|round }}
                                    </td>
									<td width="130">
										<input type="number" min="1" v-model="r.qty" class="form-control form-control-sm"/>
									</td>
									<td width="130">
										<input type="number" min="0" max="100" v-model="r.p_discount" class="form-control form-control-sm"/>
									</td>
									<td>
										{{ r.p_tax }}
									</td>
									<td>
										{{ getDiscount(r)|round }}
									</td>
									<td>
										{{ getIva(r)|round }}
									</td>
									<td width="130">
										<b>{{ getTotal(r)|round }}</b>
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('contract-templates.add',this.openModal);
            this.$root.$on('contract-templates.edit',this.openModal);
            this.$root.$on('contract-templates.create-template',this.createTemplate);
            this.getPortfolio();
        },
        data(){
            return {
            	base: window.urls['contract-templates.index'],
            	basePortfolio: window.urls['portfolio.index'],
                saving: false,
                spinner: false,
                product: null,
                products: [],
                matchedProducts: [],
                template:{
                    name: null,
                    state: null,
                    city: null,
                },
                rows: [],
                errors: {
                    name: false,
                    state: false,
                    city: false,
                },
            }
        },
        filters: {
        	round(input){
        		if(input)
        			return parseFloat(input).toFixed(2).replace(".", ",");
        		return "0,00";
        	}
        },
        methods:{
        	openModal(data){
        		this.rows = [];
                if(data){
                    this.template = {
                        id: data.id,
                        name: data.name,
                    };
                    this.processRows(data.rows);
                }else{
                    this.template={
                        name: null,
                    };
                }
                this.resetErrors();
        		$('#contractTemplateModal').modal({'backdrop':'static'});
        	},
            createTemplate(data){
                
                this.template = {
                    name: null,
                };
                this.processRowsTemplate(data);
                this.resetErrors();
                $('#contractTemplateModal').modal({'backdrop':'static'});
            },
            processRowsTemplate(rows){
                this.rows = [];
                for(var i in rows){
                    this.rows.push({
                        product_id: rows[i].id_portfolio,
                        concept: rows[i].concept,
                        price: rows[i].price,
                        p_tax: rows[i].p_tax,
                        qty: rows[i].qty,
                        p_discount: rows[i].p_discount,
                        total: rows[i].total,
                    });
                }
            },
        	processRows(rows){
        		for(var i in rows){
        			this.rows.push({
  						id: rows[i].id,
  						product_id: rows[i].product_id,
  						contract_template_id: rows[i].contract_template_id,
  						concept: rows[i].product.name,
  						qty: rows[i].qty,
  						price: rows[i].price,
  						tax: rows[i].tax,
  						p_tax: rows[i].p_tax,
  						discount: rows[i].discount,
  						p_discount: rows[i].p_discount,
  						total: rows[i].total,
        			});
        		}
        	},
            resetErrors(){
                this.errors={
                    name: false,
                    rows: false
                };
            },
            firstLetterOnUpperCase(field){
                var aux=this.template[field].split(" ");
                var final="";
                for(var i=0;i<aux.length;i++){
                    final+=aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i<aux.length-1) final+=" ";
                }
                this.template[field]=final;
            },
            getDiscount(row){
        		return row.qty * row.price * row.p_discount / 100;
        	},
        	getIva(row){
        		var discount = this.getDiscount(row);
        		return (row.qty * row.price - discount) * row.p_tax / 100
        	},
        	getTotal(row){
        		var iva = this.getIva(row);
        		var discount = this.getDiscount(row);
        		var price = row.qty * parseFloat(row.price);
        		return price + iva - discount;
        	},
        	getPortfolio(){
        		var vm = this;
        		var url = this.basePortfolio + '/table?limit=100000&page=1&orderBy=name&ascending=1&query={active:true}';
        		axios({
                    method: 'get',
                    url: url,
                    responseType: 'json',
                }).then(function(response){
                	var products = response.data.data;
                	for(var i in products){
                		var info = {
                			code: products[i].id,
                			label: products[i].name,
                			info: products[i]
                		};
                		vm.products.push(info);
                		vm.matchedProducts.push(info);
                	}
                })
                .catch(function (error) {
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
        	},
        	customSearch(options, search) {
                return options;
            },
        	searchProduct(input){
                this.matchedProducts = [];
                for(var i in this.products){
                    var product = this.products[i].label.toLocaleLowerCase();
                    if(product.indexOf(input.toLocaleLowerCase()) != -1)
                        this.matchedProducts.push(this.products[i]);
                }
                this.spinner = false;
        	},
        	selectProduct(value){
        		if(!value)
        			return ;
        		var product = value.info;
        		this.rows.push({
        			product_id: product.id,
  					concept: product.name,
  					price: product.price,
  					p_tax: product.taxes,
  					qty: 1,
  					p_discount: 0,
  					total: product.total
        		});
        		this.product = null;
        	},
        	setProductType(newProductType){
        		this.productType = newProductType;
        	},
            validate(){
                var valid = true;
                this.errors.name = this.template.name == null || this.template.name == "";
                this.errors.rows = this.rows.length == 0;
                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.template.id) url += '/' + this.template.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: {
                    	template: this.template,
                    	rows: this.rows,
                    },
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('contract-templates.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado la plantilla',
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            deleteRow(row, index){
            	this.rows.splice(index, 1);
            	if(row.id){
            		var vm = this;
		  			axios({
	                    method: 'delete',
	                    url: this.base + '/row/' + row.id,
	                    responseType: 'json',
	                }).then(function(response){
	                    vm.$root.$emit('contract-templates.updated');
	                })
	                .catch(function (error) {
	                    vm.$swal(
	                      '¡Ups!',
	                      'Parece que algo fue mal',
	                      'error'
	                    );  
	                });
            	}
            },
            cancel(){
            	$('#contractTemplateModal').modal('hide');
            }
        }
    }
</script>
