<template>
	<div class="modal fade action-sheet opax" id="portfolioModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ portfolio.id?'Editar ':'Nuevo '}} {{ title }}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
                    <div class="row">
                        <div class="col-12">
                            <label>Nombre *</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="portfolio.name"  placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
                        </div>
                        <div class="col-12">
                            <label>Código</label>
                            <input spellcheck="true" type="text" class="form-control" v-model="portfolio.code"  placeholder="Código" v-bind:class="{'is-invalid':errors.code}"/>
                        </div>
                        <div class="col-12">
                            <label>Categoría *</label>
                            <select type="text" class="form-control" v-model="portfolio.id_category" v-bind:class="{'is-invalid':errors.id_category}">
                                <option v-for="c in categories" v-bind:value="c.id">{{ c.label }}</option>
                            </select>
                        </div>
                        <div class="col-12">
                            <label>Activo *</label>
                            <select type="text" class="form-control" v-model="portfolio.active" v-bind:class="{'is-invalid':errors.active}">
                                <option value="1">Sí</option>
                                <option value="0">No</option>
                            </select>
                        </div>
                        <div class="col-12" v-if="this.title != 'suplido'">
                            <label>Precio Coste *</label>
                            <input spellcheck="true" type="number" min="0" step="0.01" class="form-control" v-model="portfolio.cost_price"  placeholder="Precio Costo" v-bind:class="{'is-invalid':errors.cost_price}" v-on:change="calculateTotalCost()"/>
                        </div>
                        <div class="col-12" v-if="this.title != 'suplido'">
                            <label> IVA Coste *</label>               
                            <select class="form-control" v-model="portfolio.cost_taxes" v-bind:class="{'is-invalid':errors.cost_taxes}" v-on:change="calculateTotalCost()">
                                <option value="0">0%</option>
                                <option value="10">10%</option>
                                <option value="21">21%</option>
                            </select>              
                        </div>
                        <div class="col-12" v-if="this.title != 'suplido'">
                            <label>Precio Total Coste</label>
                            <input type="number" class="form-control" v-model="portfolio.cost_total" placeholder="Precio Total Costo" v-bind:class="{'is-invalid':errors.cost_total}" v-on:change="calculatePriceCost()" />
                        </div>

                        <div class="col-12" v-if="this.title != 'suplido'">
                            <label>Precio Venta *</label>
                            <input spellcheck="true" type="number" min="0" step="0.01" class="form-control" v-model="portfolio.price"  placeholder="Precio" v-bind:class="{'is-invalid':errors.price}" v-on:change="calculateTotal()"/>
                        </div>
                        <div class="col-12" v-if="this.title != 'suplido'">
                            <label> IVA Venta*</label>               
                            <select class="form-control" v-model="portfolio.taxes" v-bind:class="{'is-invalid':errors.taxes}" v-on:change="calculateTotal()">
                                <option value="0">0%</option>
                                <option value="10">10%</option>
                                <option value="21">21%</option>
                            </select>              
                        </div>
                        <div class="col-12">
                            <label>Precio Total Venta</label>
                            <input type="number" class="form-control" v-model="portfolio.total" placeholder="Precio Total" v-bind:class="{'is-invalid':errors.total}" v-on:change="calculatePrice()" />
                        </div>
                    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
            this.$root.$on('portfolio.add',this.openModal);
            this.$root.$on('portfolio.edit',this.openModal);
            this.title = this.getTitle();
            this.getCategories();
        },
        beforeMount(){
            let uri = window.location.search.substring(1); 
            let params = new URLSearchParams(uri);
            let type = params.get("type")
            this.type = params.get("type");
        },
        data(){
            return {
            	base: window.urls['portfolio.index'],
                baseCategories: window.urls['portfolio.categories.index'],
                categories:[],
                saving: false,
                type: 'service',
                title: 'Servicio',
                portfolio:{
                    id_category: null,
                    type: null,
                    active: '1',
                    name: null,
                    price: null,
                    taxes: null,
                    total: null,
                    cost_price: null,
                    cost_taxes: null,
                    cost_total: null,
                    code: null
                },
                errors: {
                    id_category: false,
                    type: false,
                    active: false,
                    name: false,
                    price: false,
                    taxes: false,
                    total: false,
                    cost_price: false,
                    cost_taxes: false,
                    cost_total: false,
                },
            }
        },
        methods:{
            getTitle(){
                switch(this.type){
                    case 'product': return 'producto'; break;
                    case 'service': return 'servicio'; break;
                    case 'supplied': return 'suplido'; break;
                }
            },
            getCategories(){
                var vm=this;
                var type = {
                    type: this.type
                };
                axios({
                    method: 'get',
                    url: this.baseCategories + '/table?query=' + JSON.stringify(type) + '&orderBy=label&ascending=1',
                    responseType: 'json',
                }).then(function(response){
                    vm.categories=response.data.data;
                })
                .catch(function (error) {
                    vm.getting=false;
                    vm.$swal(
                      '¡Ups!',
                      'Parece que algo fue mal',
                      'error'
                    );  
                }); 
            },
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            calculateTotal(){
                var price = this.portfolio.price?parseFloat(this.portfolio.price):0;
                var taxes = this.portfolio.taxes?parseFloat(this.portfolio.taxes):0;
                var total = price * (1 + taxes/100);
                this.portfolio.total = this.roundTwoDecimals(total);
            },
            calculatePrice(){
                var total = this.portfolio.total?parseFloat(this.portfolio.total):0;
                var taxes = this.portfolio.taxes?parseFloat(this.portfolio.taxes):0;
                var price = total / (1 + taxes/100);
                this.portfolio.price = this.roundTwoDecimals(price);
            },
            calculateTotalCost(){
                var cost_price = this.portfolio.cost_price?parseFloat(this.portfolio.cost_price):0;
                var cost_taxes = this.portfolio.cost_taxes?parseFloat(this.portfolio.cost_taxes):0;
                var cost_total = cost_price * (1 + cost_taxes/100);
                this.portfolio.cost_total = this.roundTwoDecimals(cost_total);
            },
            calculatePriceCost(){
                var cost_total = this.portfolio.cost_total?parseFloat(this.portfolio.cost_total):0;
                var cost_taxes = this.portfolio.cost_taxes?parseFloat(this.portfolio.cost_taxes):0;
                var cost_price = cost_total / (1 + cost_taxes/100);
                this.portfolio.cost_price = this.roundTwoDecimals(cost_price);
            },
            roundTwoDecimals(input){
                var aux = input * 100;
                return Math.round(aux) / 100;
            },
        	openModal(data){
                if(data){
                    this.portfolio={
                        id: data.id,
                        id_category: data.id_category,
                        type: data.type,
                        active: data.active,
                        name: data.name,
                        price: data.price,
                        taxes: data.taxes,
                        total: data.total,
                        code: data.code,
                        cost_price: data.cost_price,
                        cost_taxes: data.cost_taxes,
                        cost_total: data.cost_total
                    };
                }else{
                    this.portfolio={
                        id_category: null,
                        type: this.type,
                        active: '1',
                        name: null,
                        price: this.type == 'supplied' ? 0.00 : null,
                        taxes: this.type == 'supplied' ? 0.00 : null,
                        total: null,
                        code: null,
                        cost_price: this.type == 'supplied' ? 0.00 : null,
                        cost_taxes: this.type == 'supplied' ? 0.00 : null,
                        cost_total: this.type == 'supplied' ? 0.00 : null,
                    };
                }
                this.resetErrors();
        		$('#portfolioModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors={
                    id_category: false,
                    type: false,
                    active: false,
                    name: false,
                    price: false,
                    taxes: false,
                    total: false,
                    code: false,
                    cost_price: false,
                    cost_taxes: false,
                    cost_total: false
                };
            },
            validate(){
                var valid = true;
                this.errors.id_category = this.portfolio.id_category == null || this.portfolio.id_category == "";
                this.errors.type = this.portfolio.type == null || this.portfolio.type == "";
                this.errors.active = this.portfolio.active == null || this.portfolio.active == "";
                this.errors.name = this.portfolio.name == null || this.portfolio.name == "";
                this.errors.price = this.portfolio.price == null || this.portfolio.price == "";
                this.errors.taxes = this.portfolio.taxes == null || this.portfolio.taxes === "";
                this.errors.total = this.portfolio.total == null || this.portfolio.total == "";
                this.errors.cost_price = this.portfolio.cost_price == null || this.portfolio.cost_price === "";
                this.errors.cost_taxes = this.portfolio.cost_taxes == null || this.portfolio.cost_taxes === "";
                this.errors.cost_total = this.portfolio.cost_total == null || this.portfolio.cost_total === "";


                for(var i in this.errors)
                    valid &= !this.errors[i];
                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url=this.base;
                var vm=this;
                if(this.portfolio.id) url += '/' + this.portfolio.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.portfolio,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving=false;
                    vm.$root.$emit('portfolio.updated');
                    vm.cancel();
                    vm.$swal(
                      'Correcto',
                      'Se ha guardado el ' + vm.title,
                      'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#portfolioModal').modal('hide');
            }
        }
    }
</script>
