<template>
	<div class="modal fade action-sheet opax" id="clientModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-xl" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">{{ client.id?'Editar cliente':'Nuevo cliente'}}</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12 col-md-3">
			                <label>Tipo de expediente *</label>
			                <select class="form-control" v-model="client.type_of_expedient" v-bind:class="{'is-invalid':errors.type_of_expedient}">
	                            <option value="complete">Completo</option>
                                <option value="reception">Recepción</option>
	                            <option value="partial">Donaciones, miembros y fetos</option>
	                            <option value="increase">Mejora</option>
	                            <option value="discount">Tipo descuento</option>
	                            <option value="coffin">Enferetramiento</option>
                                <option value="others">Otros</option>
			                </select>
			            </div>
			            <div class="col-12 col-md-3">
			                <label>Tipo de cliente *</label>
			                <select class="form-control" v-model="client.type_of_client" v-bind:class="{'is-invalid':errors.type_of_client}" v-on:change="changeTypeOfClient()">
			                    <option value="particular">Particular</option>
			                    <option value="company">Compañía de seguros</option>
			                    <option value="enterprise">Empresa</option>
			                    <!--<option value="third_party">Terceros</option>-->
			                </select>
			            </div>
			            <div class="col-12 col-md-3" v-show="client.type_of_client && client.type_of_client == 'particular'">
			                <label>Cliente *</label>
			                <select class="form-control" v-model="client.who_is" v-bind:class="{'is-invalid':errors.who_is}">
			                    <option value="solicitant">Solicitante</option>
			                    <option value="deceased">Fallecido</option>
			                    <option value="other">Otro</option>
			                </select>
			            </div>
			            <div class="col-12 col-md-3" v-show="client.type_of_expedient != 'complete' || client.type_of_client == 'company'  || client.who_is == 'deceased'">
			                <label>Seguro *</label>
			                <select class="form-control" v-model="client.id_insurance" v-bind:class="{'is-invalid':errors.who_is}" v-on:change="selectInsurance()">
			                	<option value="">Seguro</option>
			                    <option v-for="c in insurances" v-bind:value="c.id">{{ c.tradename }}</option>
			                </select>
			            </div>
					</div>
					<div v-show="client.who_is == 'other' && client.type_of_client != 'company'">
						<hr class="my-3"/>
	                    <div class="row">
				            <div class="col-12 col-md-2" v-if="client.type_of_client == 'particular'">
				                <label>Tipo de documento *</label>
				                <select class="form-control" v-model="client.document_type" v-bind:class="{'is-invalid':errors.document_type}">
				                    <option value="dni">DNI</option>
				                    <option value="passport">Pasaporte</option>
				                    <option value="other">Otros</option>
				                </select>
				            </div>
				            <div class="col-12 col-md-2" v-if="client.type_of_client == 'particular'">
				                <label>Documento * <i class="fa fa-spin fa-spinner" v-show="checkingCif"></i></label>
				                <input spellcheck="true" type="text" class="form-control" v-model="client.dni" placeholder="DNI/NIE/Pasaporte" v-on:change="validateCif()" v-bind:class="{'is-invalid':errors.dni}"/>
				            </div>
				            <div class="col-12 col-md-2" v-if="client.type_of_client == 'enterprise'">
				                <label>CIF *</label>
				                <input spellcheck="true" type="text" class="form-control" v-model="client.dni" placeholder="CIF" v-bind:class="{'is-invalid':errors.dni}"/>
				            </div>
				            <div class="col-12 col-md-2" v-if="client.type_of_client == 'enterprise'">
				                <label>Razón social *</label>
				                <input spellcheck="true" type="text" class="form-control" v-model="client.reason" placeholder="Razón social" v-bind:class="{'is-invalid':errors.reason}"/>
				            </div>
					        <div class="col-12 col-md-4">
					            <label>Nombre {{ client.type_of_client == 'enterprise'?'contacto':'' }}*</label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.name" v-on:blur="firstLetterOnUpperCase('name')" placeholder="Nombre" v-bind:class="{'is-invalid':errors.name}"/>
					        </div>
					        <div class="col-12 col-md-4">
					            <label>Primer apellido {{ client.type_of_client == 'enterprise'?'contacto':'' }}*</label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.surname" v-on:blur="firstLetterOnUpperCase('surname')" placeholder="Primer apellido" v-bind:class="{'is-invalid':errors.surname}"/>
					        </div>
					        <div class="col-12 col-md-4">
					            <label>Segundo apellido {{ client.type_of_client == 'enterprise'?'contacto':'' }}</label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.lastname" v-on:blur="firstLetterOnUpperCase('lastname')" placeholder="Segundo apellido" v-bind:class="{'is-invalid':errors.lastname}"/>
					        </div>
				            <div class="col-12 col-md-4" v-if="client.type_of_client == 'particular'">
				                <label>Parentesco *</label>
				                <input spellcheck="true" type="text" class="form-control" v-model="client.relation" placeholder="Parentesco" v-bind:class="{'is-invalid':errors.relation}"/>
				            </div>
				            <div class="col-12 col-md-4" v-if="client.type_of_client != 'enterprise'">
				                <label>Nº de siniestro *</label>
				                <input spellcheck="true" type="text" class="form-control" v-model="client.sinister_number" placeholder="Nº de siniestro" v-bind:class="{'is-invalid':errors.sinister_number}"/>
				            </div>
					    </div>
					    <hr class="my-3"/>
					    <div class="row">
					        <div class="col-12">
					            <label>Dirección {{ client.type_of_client == 'enterprise'?'facturación':'' }} *</label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.address" placeholder="Dirección" v-bind:class="{'is-invalid':errors.address}"/>
					        </div>
					        <div class="col-12 col-md-4">
					            <label>Código Postal</label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.postcode" placeholder="Código postal" v-bind:class="{'is-invalid':errors.postcode}"/>
					        </div>
					        <div class="col-12 col-md-4">
					            <label>Población *</label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.city" placeholder="Población" v-bind:class="{'is-invalid':errors.city}"/>
					        </div>
					        <div class="col-12 col-md-4">
					            <label>Provincia </label>
					            <input spellcheck="true" type="text" class="form-control" v-model="client.state" placeholder="Provincia"/>
					        </div>
					    </div>
					    <hr class="my-3"/>
					    <div class="row">
				            <div class="col-12 col-md-4">
				                <label>Email </label>
				                <input spellcheck="true" type="email" class="form-control" v-model="client.email" placeholder="Email"/>
				            </div>
				            <div class="col-12 col-md-4">
				                <label>Teléfono </label>
				                <input spellcheck="true" type="text" class="form-control" v-model="client.phone" placeholder="Teléfono"/>
				            </div>
				        </div>
				    </div>
				</div>
				<div class="modal-footer">
				    <button type="button" class="btn btn-success mr-2" v-on:click="save();" v-bind:disabled="saving"><i class="fa fa-spin fa-spinner" v-show="saving"></i> Guardar</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cancelar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
        	this.$root.$on('contracts.edit',this.setIdContract);
            this.$root.$on('contracts.clients.add',this.openModal);
            this.$root.$on('contracts.clients.edit',this.openModal);
            this.getContext();
        },
        data(){
            return {
            	base: window.urls['contracts.clients.index'],
                countries:[],
                insurances: [],
                saving: false,
                birthDate: null,
                isValidCif: true,
                checkingCif: false,
                id_contract: null,
                type_of_expedient: null,
                client:{
                    id: null,
                    id_insurance: null,
                    type_of_expedient: null,
					type_of_client: null,
					who_is: null,
					reason: null,
					name: null,
					surname: null,
					lastname: null,
					dni: null,
					address: null,
					postcode: null,
					city: null,
					state: null,
					relation: null,
					phone: null,
					email: null,
					sinister_number: null,
                },
                errors: {
                	reason: false,
					document_type: false,
                	type_of_expedient: false,
					type_of_client: false,
					name: false,
					surname: false,
					dni: false,
					address: false,
					postcode: false,
					city: false,
					relation: false,
					who_is: false,
                },
                lang: {
                    formatLocale: {
                      firstDayOfWeek: 1,
                    },
                    monthBeforeYear: false,
                },
            }
        },
        methods:{
        	getContext(){
        		var vm = this;
                axios({
                    method: 'get',
                    url: this.base + '/context',
                    responseType: 'json',
                }).then(function(response){
                    vm.loading = false;
                    vm.insurances = response.data.insurances;
                })
                .catch(function (error) {
                    console.log(error)
                    vm.saving = false;
                    var message = 'No se pudo cargar la información del servidor';
                    vm.$swal(
                        '¡Ups!',
                        message,
                        'error'
                    );
                });
        	},
        	changeTypeOfClient(){
        		if(this.client.type_of_client != 'particular')
        			this.client.who_is = 'other';
        		else
        			this.client.who_is = null;
        	},
        	setIdContract(data){
        		this.id_contract = data.id;
        		this.type_of_expedient = data.type_of_expedient;
        	},
            checkIfNumber(evt){
                var charCode = (evt.which) ? evt.which : evt.keyCode;
                if (charCode > 31 && (charCode < 48 || charCode > 57)) {
                    evt.preventDefault();;
                } else {
                    return true;
                }
            },
            selectInsurance(){
            	this.client.document_type = null;
				this.client.name = null;
				this.client.surname = null;
				this.client.dni = null;
				this.client.reason = null;
				this.client.address = null;
				this.client.postcode = null;
				this.client.city = null;
				this.client.relation = null;
				this.client.email = null;
				this.client.phone = null;
            	if(this.client.type_of_client == 'enterprise'){
            		var insurance = this.detectInsurance(this.client.id_insurance);
            		if(insurance != null){
            			this.client.document_type = 'cif';
						this.client.dni = insurance.cif;
						this.client.reason = insurance.legal_name;
						this.client.address = insurance.address;
						this.client.postcode = insurance.postcode;
						this.client.city = insurance.city;
						this.client.relation = null;
						this.client.sinister_number = null;
						this.client.email = insurance.email;
						this.client.phone = insurance.phone_number;
						this.client.state = insurance.state;
						this.client.name = insurance.name;
						this.client.surname = insurance.surname;
						this.client.lastname = insurance.lastname;
            		}
            	}
            },
            detectInsurance(id){
            	for(var i in this.insurances)
            		if(this.insurances[i].id == id)
            			return this.insurances[i];
        		return null;
            },
        	openModal(data){
                if(data){
                    this.client = {
                    	id: data.id,
                    	id_contract: data.id_contract,
                    	id_insurance: data.id_insurance,
                        type_of_expedient: data.type_of_expedient,
						type_of_client: data.type_of_client,
						document_type: data.document_type,
						who_is: data.who_is,
						reason: data.reason,
						name: data.name,
						surname: data.surname,
						lastname: data.lastname,
						dni: data.dni,
						address: data.address,
						postcode: data.postcode,
						city: data.city,
						state: data.state,
						relation: data.relation,
						phone: data.phone,
						email: data.email,
						sinister_number: data.sinister_number
                    };
                }else{
                    this.client = {
                    	id_contract: this.id_contract,
                    	id_insurance: null,
                        type_of_expedient: this.type_of_expedient,
						type_of_client: null,
						document_type: null,
						who_is: null,
						reason: null,
						name: null,
						surname: null,
						lastname: null,
						dni: null,
						address: null,
						postcode: null,
						city: null,
						state: null,
						relation: null,
						phone: null,
						email: null,
						id_insurance: null,
						relation: null,
						sinister_number: null,
                    };
                }
                this.resetErrors();
        		$('#clientModal').modal({'backdrop':'static'});
        	},
            resetErrors(){
                this.errors = {
                    document_type: false,
                	type_of_expedient: false,
					type_of_client: false,
					name: false,
					surname: false,
					dni: false,
					address: false,
					postcode: false,
					city: false,
					relation: false,
					who_is: false
                };
            },
            firstLetterOnUpperCase(field){
                if(this.client[field] == null)
                    return;
                var aux=this.client[field].split(" ");
                var final = "";
                for(var i = 0;i < aux.length; i++){
                    final += aux[i].charAt(0).toUpperCase() + aux[i].slice(1);
                    if(i < aux.length - 1) final += " ";
                }
                this.client[field] = final;
            },
            validateCif(){
                this.isValidCif = this.client.dni != null;
                if(this.isValidCif && this.client.id_nationality == 205){
                    var regex = new RegExp('([a-z]|[A-Z]|[0-9])[0-9]{7}([a-z]|[A-Z]|[0-9])');
                    this.isValidCif = this.client.dni.match(regex);
                }
                if(!this.isValidCif){
                    this.showValidCifAlert();
                }
                else{
                    this.checkCif();
                }
                this.errors.dni = !this.isValidCif;
            },
             checkCif(){
                var cif = JSON.stringify({ check_cif: this.client.dni });
                var vm = this;
                this.checkingCif = true;
                axios({
                    method: 'get',
                    url: this.base + '/table?query=' + cif,
                    responseType: 'json',
                }).then(function(response){
                    vm.checkingCif = false;
                    if(response.data.count == 1){
                        var data = response.data.data[0];
                        vm.loadClient(data);
                    }else{
                        vm.client.id = null;
                    }
                })
                .catch(function (error) {
                    vm.checkingCif = false;
                    vm.$swal(
                        '¡Ups!',
                        'No se pudo cargar la información del servidor',
                        'error'
                    );  
                }); 
            },
            validate(){
                var valid = true;
                this.errors = {
                	type_of_expedient: false,
					type_of_client: false,
					who_is: false,
					document_type: false,
					name: false,
					surname: false,
					dni: false,
					address: false,
					postcode: false,
					city: false,
					relation: false,
					reason: false,
					id_insurance: false,
                };
                this.errors.type_of_client = this.client.type_of_client == null || this.client.type_of_client == "";
                if(this.errors.type_of_client){
                	valid = false;
                }
                else if(this.client.type_of_client == 'particular'){
                	this.errors.type_of_expedient = this.client.type_of_expedient == null || this.client.type_of_expedient == "";
					this.errors.who_is = this.client.who_is == null;
					this.errors.document_type = this.client.type_of_client == 'particular' && this.client.who_is == 'other' && (this.client.document_type == null || this.client.document_type == "");
					this.errors.name = this.client.who_is == 'other' && (this.client.name == null || this.client.name == "");
					this.errors.surname = this.client.who_is == 'other' && (this.client.surname == null || this.client.surname == "");
					this.errors.dni = this.client.who_is == 'other' && (this.client.dni == null || this.client.dni == "");
					this.errors.address = this.client.who_is == 'other' && (this.client.address == null || this.client.address == "");
					this.errors.postcode = this.client.who_is == 'other' && (this.client.postcode == null || this.client.postcode == "");
					this.errors.city = this.client.who_is == 'other' && (this.client.city == null || this.client.city == "");
					this.errors.relation = this.client.type_of_client == 'particular' && this.client.who_is == 'other' && (this.client.relation == null || this.client.relation == "");
                }else if(this.client.type_of_client == 'enterprise'){
					this.errors.reason = this.client.type_of_client == 'enterprise' && this.client.who_is == 'other' && (this.client.reason == null || this.client.reason == "");
                }else if(this.client.type_of_client == 'company'){
					this.errors.id_insurance = this.client.id_insurance == null || this.client.id_insurance == "";
                }
                for(var i in this.errors)
                    valid &= !this.errors[i];

                if(!valid)
                    this.showRequiredMessage();
                return valid;
            },
            showRequiredMessage(){
                this.$swal(
                    'Atención',
                    'Hay datos obligatorios por rellenar',
                    'warning'
                );
            },
            save(){
                if(!this.validate())
                    return;
                var url = this.base;
                var vm = this;
                if(this.client.id) url += '/' + this.client.id;
                this.saving=true;
                axios({
                    method: 'post',
                    url: url,
                    data: this.client,
                    responseType: 'json',
                }).then(function(response){
                    vm.saving = false;
                    vm.$root.$emit('contracts.clients.updated', response.data);
                    vm.cancel();
                    vm.$swal(
                        'Correcto',
                        'Se ha guardado al cliente',
                        'success'
                    );
                })
                .catch(function (error) {
                    vm.saving = false;
                    var message = 'Parece que algo fue mal';
                    if(error.response.status == 400)
                        message = error.response.data.message;
                    if(error.response.status == 404)
                        message = "El registro ya no existe";
                    vm.$swal(
                      '¡Ups!',
                      message,
                      'error'
                    );
                });
            },
            cancel(){
            	$('#clientModal').modal('hide');
            }
        }
    }
</script>
