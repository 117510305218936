<template>
	<div class="modal fade action-sheet opax" id="shareWithCutomerModal" tabindex="-1" role="dialog">
	    <div class="modal-dialog modal-md" role="document">
	    	<div class="modal-content">
			    <div class="modal-header">
				    <h5 class="modal-title">Enviar a cliente</h5>
				    <button type="button" class="close" data-dismiss="modal" aria-label="Close" v-on:click="cancel();">
				    <span aria-hidden="true">&times;</span>
				    </button>
				</div>
				<div class="modal-body">
					<div class="row">
						<div class="col-12">
							<label>Link</label>
							<input type="text" class="form-control" v-bind:value="finalUrl" readonly v-on:click="copyToClipboard(finalUrl)">
							<!--<small>Haz click para copiar</small>-->
						</div>
						<div class="col-12">
							<label>Pin de acceso</label>
							<input type="text" class="form-control" v-bind:value="contract.pin" readonly v-on:click="copyToClipboard(contract.pin)">
							<!--<small>Haz click para copiar</small>-->
						</div>
						<div class="col-12">
							<label>Email de envío</label>
							<input type="text" class="form-control" v-bind:value="solicitant.email" v-on:click="copyToClipboard(contract.pin)">
						</div>
                    </div>
				</div>
				<div class="modal-footer">
					<button type="button" class="btn btn-success" v-on:click="send();">Enviar al correo</button>
				    <button type="button" class="btn btn-danger" data-dismiss="modal" v-on:click="cancel();">Cerrar</button>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
    export default {
        mounted() {
        	this.$root.$on('contracts.share',this.openModal);
        },
        data(){
            return {
            	baseUrl: window.location.origin,
            	finalUrl: null,
                contract:{
                    uri: null,
					pin: null
                },
                solicitant:{
                	name: null,
                	email: null
                }
            }
        },
        methods:{
            openModal(data){
                if(data){
                    this.contract={
                        uri: data.uri,
                        pin: data.pin
                    };
                    this.solicitant = {
                    	name: data.solicitant.name,
                    	email: data.solicitant.email
                    };
                    this.finalUrl = this.baseUrl + '/client-contracts/' + this.contract.uri;
                }
        		$('#shareWithCutomerModal').modal('show');
        	},
        	copyToClipboard(){
        		var toCopy = this.contract.uri;
        		setTimeout(function(){
        			var inputc = document.body.appendChild(document.createElement("input"));
					inputc.value = toCopy;
					inputc.focus();
					inputc.select();
					inputc.setSelectionRange(0, 99999);
					inputc.parentNode.removeChild(inputc);
					document.execCommand('copy');
        		},100)
        	},
        	send(){

        	},
            cancel(){
            	$('#shareWithCutomerModal').modal('hide');
            }
        }
    }
</script>
